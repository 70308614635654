import { pdForms } from '@/js/UI/validation'
import { Control } from '@peckadesign/pd-naja/dist/types'

class FilterSelectOptionsControl implements Control {
	initialize(context: Element | Document): void {
		context.querySelectorAll<HTMLSelectElement>('[data-filter-select-parent]').forEach((select) => {
			const parentSelect = document.getElementById(
				select.dataset.filterSelectParent as string
			) as HTMLSelectElement | null

			if (!parentSelect) {
				return
			}

			const allOptions = new Map<string, HTMLOptionElement[]>()
			let defaultOption: HTMLOptionElement | undefined

			Array.from(select.options).forEach((option) => {
				const parentValue = option.dataset.parentValue || ''
				if (parentValue === '' && !defaultOption) {
					defaultOption = option // Store the default option
				}
				if (!allOptions.has(parentValue)) {
					allOptions.set(parentValue, [])
				}
				allOptions.get(parentValue)?.push(option)
			})

			parentSelect.addEventListener('change', () => {
				select.disabled = parentSelect.value === ''

				const validOptions = [...(defaultOption ? [defaultOption] : []), ...(allOptions.get(parentSelect.value) || [])]
				select.replaceChildren(...validOptions)

				select.value = defaultOption?.value || ''
				pdForms.removeMessages(select, true)
			})

			parentSelect.dispatchEvent(new Event('change'))
		})
	}
}

export default new FilterSelectOptionsControl()
