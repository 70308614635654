import { addMessage } from '@/js/UI/pdForms/addMessage'
import { pdForms } from '@/js/UI/validation'

export class BrowserGeolocation {
	public static readonly selector = '.js-geolocation'

	private coordinatesInput: HTMLInputElement
	private geolocationButton: HTMLButtonElement
	private submitButton: HTMLButtonElement

	public browserGeolocationOptions: PositionOptions = {
		enableHighAccuracy: true,
		timeout: 27000
	}

	public constructor(context: HTMLElement) {
		const coordinatesInput = context.querySelector<HTMLInputElement>(`${BrowserGeolocation.selector}__coordinates`)
		const geolocationButton = context.querySelector<HTMLButtonElement>(
			`${BrowserGeolocation.selector}__geolocation-btn`
		)
		const submitButton = context.querySelector<HTMLButtonElement>(`${BrowserGeolocation.selector}__btn`)

		if (!coordinatesInput || !geolocationButton || !submitButton) {
			throw new Error('BrowserGeolocation: Missing element for coordinates, geolocation button or submit button!')
		}

		this.coordinatesInput = coordinatesInput
		this.geolocationButton = geolocationButton
		this.submitButton = submitButton

		this.geolocationButton.addEventListener('click', this.browserGeolocation.bind(this))
	}

	private browserGeolocation(): void {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				this.submitCoordinates.call(this, position.coords.latitude, position.coords.longitude)
			},
			(error) => {
				const button = this.geolocationButton!
				const customMessage = button.dataset[`geolocationError-${error.code}`]

				pdForms.removeMessages(button, false)
				addMessage(button, customMessage ?? error.message, 'warning', false, false)
			},
			this.browserGeolocationOptions
		)
	}

	private submitCoordinates(lat: number, lng: number): void {
		const locationResult = {
			lat,
			lng
		}

		this.coordinatesInput.value = JSON.stringify(locationResult)
		this.submitButton.click()
		this.coordinatesInput.value = ''
	}
}
