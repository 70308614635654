export class Countdown {
	public static readonly selector = '.js-countdown'

	private element: HTMLElement
	private daysElement: HTMLElement
	private hoursElement: HTMLElement
	private minutesElement: HTMLElement
	private secondsElement: HTMLElement
	private remaining: number
	private countdownInterval: ReturnType<typeof setInterval> | undefined

	public constructor(element: HTMLElement) {
		const daysElement = element.querySelector<HTMLElement>(`${Countdown.selector}__days`)
		const hoursElement = element.querySelector<HTMLElement>(`${Countdown.selector}__hours`)
		const minutesElement = element.querySelector<HTMLElement>(`${Countdown.selector}__minutes`)
		const secondsElement = element.querySelector<HTMLElement>(`${Countdown.selector}__seconds`)

		const datetime = element.dataset.countdownDatetime

		if (!daysElement || !hoursElement || !minutesElement || !secondsElement) {
			throw new Error('Countdown: Missing some elements for remaining time.')
		}

		if (!datetime) {
			throw new Error('Countdown: No datetime provided.')
		}

		this.element = element
		this.daysElement = daysElement
		this.hoursElement = hoursElement
		this.minutesElement = minutesElement
		this.secondsElement = secondsElement
		const now = Date.now()
		this.remaining = Math.floor((Date.parse(datetime) - now) / 1000)

		this.startCountdown()
	}

	private startCountdown(): void {
		this.displayRemainingTime()

		this.countdownInterval = setInterval(() => {
			this.remaining--
			this.displayRemainingTime()
		}, 1000)
	}

	private displayRemainingTime(): void {
		if (this.remaining > 0) {
			let seconds = this.remaining

			const remainingDays = Math.floor(seconds / 3600 / 24)
			seconds -= remainingDays * 3600 * 24

			const remainingHours = Math.floor(seconds / 3600)
			seconds -= remainingHours * 3600

			const remainingMinutes = Math.floor(seconds / 60)
			seconds -= remainingMinutes * 60

			const remainingSeconds = seconds

			this.daysElement.textContent = remainingDays.toString()
			this.hoursElement.textContent = remainingHours.toString().padStart(2, '0')
			this.minutesElement.textContent = remainingMinutes.toString().padStart(2, '0')
			this.secondsElement.textContent = remainingSeconds.toString().padStart(2, '0')
		} else {
			clearInterval(this.countdownInterval)
		}
	}
}
