import { QuantityDiscount } from '@/js/App/QuantityDiscount'
import { Control } from '@peckadesign/pd-naja/dist/types'

class QuantityDiscountControl implements Control {
	public initialize(context: Element | Document) {
		const quantityDiscounts = context.querySelectorAll<HTMLElement>('.js-quantity-discount')

		quantityDiscounts.forEach((quantityDiscount) => new QuantityDiscount(quantityDiscount))
	}
}

export default new QuantityDiscountControl()
