import { MediaGalleryContentLoader } from '@peckadesign/pd-modal'

export default {
	i18n: {
		lv: {
			prev: 'Iepriekšējais attēls',
			next: 'Nākamais attēls',
			showImage: 'Rādīt attēlu',
			imageOf: 'no'
		},
		ru: {
			prev: 'Предыдущее изображение',
			next: 'Следующее изображение',
			showImage: 'Показать изображение',
			imageOf: 'из'
		}
	},
	thumbnails: true,
	sizes: function (this: MediaGalleryContentLoader, opener: HTMLAnchorElement): string {
		const aspectRatio = opener.dataset.modalImageAspectRatio ? Number(opener.dataset.modalImageAspectRatio) : 1110 / 900

		// Images are also limited by the height of the viewport. Therefore, we need to choose the minimum of the
		// following numbers:
		// 1) If vertical layout is used (default):
		//    a) 100vw - 2 * (modal X margin) - 2 * (modal X padding)
		//    b) Min((100vw - 2 * (modal X margin) - 2 * (modal X padding)), (maximum image height from CSS)) * (image aspect ratio)
		//       In vertical layout, the image height is constrained not by viewport, but by aspect ratio - it is always
		//       square (up po 900px height from CSS), so we can simply multiply Min(width, 900px) with image aspect
		//       ratio.
		//
		// 2) If horizontal layout is used `(aspect-ratio >= 4 / 3) and (width >= 800px)`:
		//    a)  100vw - (modal X margin) - (modal X padding) - (outer thumbnails width)
		//    b)  Min((100vh - (modal Y margin) - (modal Y padding)), (maximum image height from CSS)) * (image aspect ratio)
		return (
			// Horizontal layout
			`(aspect-ratio >= 4 / 3) and (width >= 1540px) calc(min(1110px,                                        min(100vh - 2 * 40px - 2 * 30px, 900px) * ${aspectRatio})),` +
			`(aspect-ratio >= 4 / 3) and (width >= 1280px) calc(min(100vw - 4 * 50px - 2 * 75px - 2 * 30px - 20px, min(100vh - 2 * 40px - 2 * 30px, 900px) * ${aspectRatio})),` +
			`(aspect-ratio >= 4 / 3) and (width >= 992px)  calc(min(100vw - 4 * 30px - 2 * 75px - 2 * 30px - 20px, min(100vh - 2 * 40px - 2 * 30px, 900px) * ${aspectRatio})),` +
			`(aspect-ratio >= 4 / 3) and (width >= 800px)  calc(min(100vw - 4 * 30px -     75px - 2 * 30px,        min(100vh - 2 * 40px - 2 * 30px, 900px) * ${aspectRatio})),` +
			// Vertical layout
			`(width >= 1310px) calc(min(1110px,           min(1110px,           900px) * ${aspectRatio})),` +
			`(width >= 1280px) calc(min(100vw - 4 * 50px, min(100vw - 4 * 50px, 900px) * ${aspectRatio})),` +
			`(width >= 1230px) calc(min(1110px,           min(1110px,           900px) * ${aspectRatio})),` +
			`(width >= 768px)  calc(min(100vw - 4 * 30px, min(100vw - 4 * 30px, 900px) * ${aspectRatio})),` +
			`                  calc(min(100vw - 2 * 15px, min(100vw - 2 * 15px, 900px) * ${aspectRatio}))`
		)
	}
}
