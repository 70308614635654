import { HTMLSliderElement, Slider, SliderData, SliderValueId } from '@/js/App/Slider'

type DailyFeedRationResult = {
	columns: string[]
	dailyDosage: number
	pricePerDay: string
}

type DailyFeedRationDefault = {
	dailyDosage: string
	pricePerDay: string
}

type DailyFeedRationMatrix = {
	name: string
	columns: SliderData[]
}

type DailyFeedRationData = {
	familyBadge: boolean
	defaultValue: DailyFeedRationDefault
	matrixes: DailyFeedRationMatrix[]
	values: DailyFeedRationResult[]
}

export class DailyFeedRation {
	private sliders: Slider[] = []
	public static readonly selector: string = '.js-daily-feed-ration'

	private readonly data: DailyFeedRationData
	private readonly minimumDosage: number
	private readonly bowlFullnessStepCount: number
	private readonly bowlFullnessDosageStep: number

	private readonly element: HTMLElement
	private readonly dailyDosageElement: HTMLElement
	private readonly pricePerDayElement: HTMLElement

	private readonly resultsElement: HTMLElement
	private readonly noResultsElement: HTMLElement
	private readonly illustElement: HTMLElement

	private readonly formatter: Intl.NumberFormat

	public constructor(element: HTMLElement) {
		const data = element.dataset.dailyFeedRationData
		const pricePerDayElement = element.querySelector<HTMLElement>(`${DailyFeedRation.selector}__price`)
		const dailyDosageElement = element.querySelector<HTMLElement>(`${DailyFeedRation.selector}__dosage`)

		const resultsElement = element.querySelector<HTMLElement>(`${DailyFeedRation.selector}__results`)
		const noResultsElement = element.querySelector<HTMLElement>(`${DailyFeedRation.selector}__no-results`)

		const illustElement = element.querySelector<HTMLElement>(`${DailyFeedRation.selector}__illust`)

		if (!data) {
			throw new Error('DailyFeedRation: Missing data attribute for daily feed ration.')
		}

		if (!dailyDosageElement || !pricePerDayElement || !resultsElement || !noResultsElement || !illustElement) {
			throw new Error(
				'DailyFeedRation: Missing element for daily dosage, price per day, results, no results message or illust.'
			)
		}

		this.data = JSON.parse(data) as DailyFeedRationData
		this.minimumDosage = this.getMinimumDosage()
		this.bowlFullnessStepCount = illustElement.childElementCount
		this.bowlFullnessDosageStep = (this.getMaximumDosage() - this.minimumDosage) / (this.bowlFullnessStepCount - 2) // -2 because 1 is for empty bowl and 1 why not

		this.element = element
		this.dailyDosageElement = dailyDosageElement
		this.pricePerDayElement = pricePerDayElement

		this.resultsElement = resultsElement
		this.noResultsElement = noResultsElement
		this.illustElement = illustElement

		this.formatter = new Intl.NumberFormat(document.documentElement.lang, {
			style: 'unit',
			unit: 'gram'
		})

		this.initializeSliders()
	}

	public initializeSliders(): void {
		const sliderElements = this.element.querySelectorAll<HTMLSliderElement>(`${DailyFeedRation.selector}__slider`)

		sliderElements.forEach((sliderElement) => {
			const slider = new Slider(sliderElement)

			this.sliders.push(slider)
			slider.api.on('slide', this.configurationChanged.bind(this))
		})

		this.configurationChanged()
	}

	private configurationChanged(): void {
		const currentConfig: SliderValueId[] = this.getCurrentConfig()
		const result = this.getValuePerConfig(currentConfig)

		if (result) {
			this.showResults(result)
		} else {
			this.showNoResults()
		}
	}

	private getMinimumDosage(): number {
		return Math.min(...this.data.values.map((result) => result.dailyDosage))
	}

	private getMaximumDosage(): number {
		return Math.max(...this.data.values.map((result) => result.dailyDosage))
	}

	private showResults(result: DailyFeedRationResult) {
		this.resultsElement.hidden = false
		this.noResultsElement.hidden = true
		this.resultsElement.ariaHidden = String(false)
		this.noResultsElement.ariaHidden = String(true)

		this.updateDailyDosage(result.dailyDosage)
		this.updatePricePerDay(result.pricePerDay)

		this.illustElement.dataset.bowlFullness = String(
			Math.round((result.dailyDosage - this.minimumDosage) / this.bowlFullnessDosageStep + 1)
		)
	}

	private showNoResults(): void {
		this.resultsElement.hidden = true
		this.noResultsElement.hidden = false
		this.resultsElement.ariaHidden = String(true)
		this.noResultsElement.ariaHidden = String(false)

		this.illustElement.dataset.bowlFullness = String(0)
	}

	private getCurrentConfig(): SliderValueId[] {
		return this.sliders.map((slider) => slider.getId() as SliderValueId)
	}

	private getValuePerConfig(config: SliderValueId[]): DailyFeedRationResult | undefined {
		const configString = JSON.stringify(config)

		return this.data.values.find((value) => JSON.stringify(value.columns) === configString)
	}

	private updatePricePerDay(pricePerDay: string): void {
		this.pricePerDayElement.innerHTML = pricePerDay
	}

	private updateDailyDosage(dailyDosage: number): void {
		this.dailyDosageElement.innerHTML = this.formatter.format(dailyDosage)
	}
}
