import { isDatasetFalsy } from '@peckadesign/pd-naja'
import React, { Fragment, ReactNode } from 'jsx-dom'
import { ConditionalWrappers } from '@/js/helpers/JSXConditionalWrappers'

export interface SpinnerProps {
	overlay?: boolean
	size?: number
	sticky?: boolean
}

export const Spinner = ({ overlay = false, size = 50, sticky = true }: SpinnerProps): Element => {
	return (
		<div
			className={{
				'spinner flex flex-column items-center justify-center text-center': true,
				'spinner--overlay absolute inset-0 z-spinner rounded-inherit': overlay,
				'my-5': !overlay
			}}
			aria-label="Načítání…"
		>
			<ConditionalWrappers
				wrappers={[
					overlay &&
						sticky &&
						((children: ReactNode) => <div className="spinner__sticky sticky h-0 inset-y-[50vh]">{children}</div>),
					overlay && sticky && ((children: ReactNode) => <div className="-translate-y-1/2">{children}</div>)
				]}
			>
				<Fragment>
					<svg width={size} height={size} viewBox="0 0 50 50" fill="currentColor" className="spinner__spinner">
						<path d="M49 2.7c0-2.07-3.16-2.08-3.18 0-2.5-.14-5.86 1.28-7.95-.52-3.4-2.88-10.25.26-12.37 3.58-2.48 3.5-1.56 7.6-3.07 11.56-.93 3.32-3.62 5.56-6.06 7.8-4.2 3.84-7.43 8.05-7.39 14.2.36 12.06 11.32 10.1 20.01 10.19h9.72c.91 0 1.55-.45 1.49-1.17-.14-1.6-.56-3.1-2.2-3.84-.68-.3-.8-.73-.8-1.4.02-7.5.02-14.99.01-22.48 0-1.92-.52-4.93 1.78-5.89 5.5-2.64 9.5-3.25 8.83-10.49.68-.18 1.18-.8 1.18-1.53Zm-22.42 9.91c1.28.56 2.38 1.52 3.8 1.8 1.8.53 5.52-.32 4.3-2.83-.52-.85-1.39-1.44-2.05-2.15-.79-.84-.98-1.88-1.02-2.98-.02-.52.4-.61.65-.17q1.18 1.64 3.1 3.25c2.72 2.29 1.68 5.34-.94 6.3-1.8.75-3.98.51-6.34-.62-1.31-.62-1.36-.9-1.82-1.81-.22-.46-.08-.87.32-.79ZM38 46.95c.99 1.28-2.64.56-3.26.74-.41 0-.43-.36-.5-.58-.47-1.3-1.15-2.34-2.63-2.64-.5-.1-.42-.55-.42-.92v-8.42c.27-1.67-1.88-1.88-1.8.05 0 3.24.02 6.48-.02 9.72-.01.84.37 1.17 1.1 1.17.8.06 1.9.55 1.84 1.43-.05.38-.65.17-1 .17-1.76.02-3.52 0-5.29.02-.44.01-.74-.12-.8-.53-.17-1.07-.98-1.71-1.76-2.2-.88-.55-.32-.88.06-1.1 4.72-3.36 2.43-10.67-2.97-11.75-1.16-.24-3.86-.16-3.55 1.53.16.57.59.65 1.22.42 2.71-1 5.41 1.5 5.95 3.88.52 2.24-.83 3.76-2.49 4.94-.66.65-2.2.63-2.12 1.79.04.5.33.7.81.83a5.1 5.1 0 0 1 2.8 1.69c.2.25.34.48-.14.49-8.09.48-13.42-1.85-11.96-10.95C12.4 29.5 18.25 25.94 24.9 23.4c2.5-1.14 2.5-1.36 6.6-2.73.64-.22 3.86-1.82 3.93-.01.13 7.83-.02 15.68-.04 23.52-.17 1.88 1.92 1.45 2.62 2.77ZM37.74 7.7c-1.33 0-1.33-2.04 0-2.05 1.34.01 1.34 2.05 0 2.05Z" />
						<path
							d="M9.39 40.63a3.03 3.03 0 0 0-1.03-.52c-.95-.28-1.35-.66-2.11-1.4-2.18-2.07-2.76-4.7-2.5-7.53.16-1.63.6-3.26 2.12-4.35.4-.28.9-.91.21-1.63a1.19 1.19 0 0 0-1.62-.17 6.58 6.58 0 0 0-2.52 3.58c-.66 2.33-.74 4.7-.05 7.06.96 3.31 2.85 5.68 6.33 6.54.36.09.53.18.7.19.46.03.95-.2.98-.74.01-.24 0-.52-.51-1.03Z"
							class="spinner__animate"
						/>
					</svg>
				</Fragment>
			</ConditionalWrappers>
		</div>
	)
}

export const getSpinnerProps = (element: Element): SpinnerProps => {
	const spinnerSize = element.getAttribute('data-naja-spinner-size')
	const spinnerSticky = !isDatasetFalsy(element, 'najaSpinnerSticky')

	return {
		overlay: true,
		size: spinnerSize ? parseInt(spinnerSize) : undefined,
		sticky: spinnerSticky
	}
}

export const getNonStickySpinnerProps = (element: Element): SpinnerProps => {
	const spinnerSize =
		element.getAttribute('data-naja-btn-spinner-size') || element.getAttribute('data-naja-spinner-size')
	const spinnerOverlay = !isDatasetFalsy(element, 'najaSpinnerOverlay')

	return {
		overlay: spinnerOverlay,
		size: spinnerSize ? parseInt(spinnerSize) : 30,
		sticky: false
	}
}
