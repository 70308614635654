import { Dropdown } from '@/js/App/Dropdown'
import { Control } from '@peckadesign/pd-naja/dist/types'

class DropdownControl implements Control {
	public initialize(context: Element | Document): void {
		const dropdowns = context.querySelectorAll<HTMLElement>(Dropdown.selector)

		dropdowns.forEach((dropdown) => new Dropdown(dropdown))
	}
}

export default new DropdownControl()
