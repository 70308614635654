import { tooltipOptions } from '@/js/Controls/TooltipControl'
import React from 'jsx-dom'
import Haptic from '@/js/App/Haptic'
import tippy, { Instance } from 'tippy.js'

type ButtonOperation = 'getDecrement' | 'getIncrement'
type ButtonSize = undefined | 'xs'

export class QuantityInput {
	private element: HTMLElement
	private input: HTMLInputElement
	private inputCopy: HTMLSpanElement

	private decrementButton: HTMLButtonElement
	private incrementButton: HTMLButtonElement

	private min: number
	private max: number

	private rapidChangeTimer: ReturnType<typeof setTimeout> | null = null

	private readonly startRapidChangeEvents = ['mousedown', 'touchstart'] as const
	private readonly stopRapidChangeEvents = ['mouseup', 'mouseleave', 'touchend', 'touchcancel'] as const

	private rapidChangeFlag: boolean = false
	private valueChangedFlag: boolean = false

	private tooltip: Instance | undefined = undefined

	public constructor(element: HTMLElement) {
		const input = element.querySelector<HTMLInputElement>('.js-quantity-input__input')
		const wrap = element.querySelector<HTMLElement>('.js-quantity-input__input-wrap')

		if (!input || !wrap) {
			throw new Error('QuantityInput: Missing input element.')
		}

		this.element = element
		this.input = input

		this.min = parseInt(input.min) || 0
		this.max = parseInt(input.max) || Number.MAX_SAFE_INTEGER

		const size = element.dataset.size as ButtonSize
		this.decrementButton =
			element.querySelector<HTMLButtonElement>('.js-quantity-input__btn--dec') || this.getButton('−', size)
		this.incrementButton =
			element.querySelector<HTMLButtonElement>('.js-quantity-input__btn--inc') || this.getButton('+', size)

		this.inputCopy = (<span aria-hidden="true" class="sr-only"></span>) as HTMLSpanElement

		this.element.insertAdjacentElement('beforeend', this.decrementButton)
		this.element.insertAdjacentElement('beforeend', this.incrementButton)
		wrap.insertAdjacentElement('beforeend', this.inputCopy)

		this.calculateInputWidth()
		this.attachHandlers()
		this.setButtonsState()
		this.prepareTooltip()
	}

	private attachHandlers(): void {
		this.input.addEventListener('change', this.adjustValueToRange.bind(this))
		this.input.addEventListener('input', this.calculateInputWidth.bind(this))
		this.input.addEventListener('input', this.setButtonsState.bind(this))
		this.input.addEventListener('focus', () => {
			this.input.select()
		})
		;[this.decrementButton, this.incrementButton].forEach((button) => {
			button.addEventListener('click', this.handleClick.bind(this))

			// long tap on Android would open context menu; for iOS it is necessary to use
			// `-webkit-touch-callout: none;` in CSS
			button.addEventListener('contextmenu', (event) => event.preventDefault())

			this.startRapidChangeEvents.forEach((eventName) => {
				button.addEventListener(eventName, this.startRapidChange.bind(this))
			})
			this.stopRapidChangeEvents.forEach((eventName) => {
				button.addEventListener(eventName, this.stopRapidChange.bind(this))
			})
		})
	}

	private prepareTooltip(): void {
		const maxMessage = this.element.dataset.quantityInputMaxMsg

		if (!maxMessage) {
			return
		}

		this.tooltip = tippy(this.element, {
			...tooltipOptions,
			maxWidth: 175,
			trigger: 'manual',
			interactive: true,
			onHide: () => {
				// There is no `event` passed to this callback, so we have to rely on the deprecated `window.event`.
				if (event && event.target === this.element) {
					return false
				}
			},
			content: maxMessage.replace('%d', this.max.toString())
		})
	}

	private getButton(text: string, size: ButtonSize): HTMLButtonElement {
		return (
			<button
				type="button"
				aria-hidden="true"
				tabIndex={-1}
				class={['form-quantity__btn btn btn--secondary btn--outline', size ? `btn--${size}` : null]}
			>
				{text}
			</button>
		) as HTMLButtonElement
	}

	private calculateInputWidth(): void {
		this.inputCopy.innerHTML = this.input.value
		// +2 is for "rendering safety margin"
		this.input.style.width = `${this.inputCopy.scrollWidth + 2}px`
	}

	private getValue(): number {
		return parseInt(this.input.value)
	}

	private setValue(value: number, emitEvent: boolean = true): void {
		this.input.value = String(value)

		if (emitEvent) {
			this.input.dispatchEvent(new CustomEvent('change', { bubbles: true, detail: { isTrigger: true } }))
		}

		this.calculateInputWidth()
		this.setButtonsState()

		if (value >= this.max) {
			this.tooltip?.show()
		}
	}

	private clampValue(value: number): number {
		return isNaN(value) ? this.min : Math.min(Math.max(value, this.min), this.max)
	}

	private adjustValueToRange(event: Event | CustomEvent): void {
		if ('detail' in event && event.detail.isTrigger) {
			return
		}

		const value = this.getValue()
		const newValue = this.clampValue(value)

		if (value !== newValue) {
			event.stopImmediatePropagation()
			this.setValue(newValue)
		}
	}

	private setButtonsState(): void {
		const value = this.getValue()

		this.decrementButton.disabled = value <= this.min
		this.incrementButton.disabled = value >= this.max
	}

	private getButtonOperation(event: Event): ButtonOperation {
		return event.currentTarget === this.decrementButton ? 'getDecrement' : 'getIncrement'
	}

	private getIncrement(value: number): number {
		value++

		return Math.min(value, this.max)
	}

	private getDecrement(value: number): number {
		value--

		return Math.max(value, this.min)
	}

	private handleClick(event: MouseEvent): void {
		event.preventDefault()

		if (!this.rapidChangeFlag && !(event.target as HTMLButtonElement).disabled) {
			let value = this.getValue()

			value = this[this.getButtonOperation(event)](value)
			this.setValue(value)
			Haptic.vibrate(Haptic.VIBRATE_DEFAULT)
		}

		this.rapidChangeFlag = false
	}

	private startRapidChange(event: MouseEvent | TouchEvent): void {
		let value = this.getValue()
		const operation = this.getButtonOperation(event)
		let counter = 0

		const rapidChange = () => {
			counter++
			const newValue = this[operation](value)

			this.rapidChangeFlag = true

			if (value !== newValue) {
				value = newValue

				this.valueChangedFlag = true
				this.setValue(value, false)

				Haptic.vibrate(Haptic.VIBRATE_DEFAULT)

				if (counter === 1) {
					this.setButtonsState()
				}
			} else {
				// Min / max value achieved as value didn't change → we don't need to wait for mouse release
				if (this.valueChangedFlag) {
					this.stopRapidChange()
				}
				return
			}

			let delay = 150
			if (counter > 10) {
				delay = Math.max(10, delay - 5 * counter)
			}
			this.rapidChangeTimer = setTimeout(rapidChange, delay)
		}

		this.rapidChangeTimer = setTimeout(rapidChange, 500)
	}

	private stopRapidChange() {
		this.rapidChangeFlag = false
		Haptic.stopVibrate()

		if (this.rapidChangeTimer) {
			clearTimeout(this.rapidChangeTimer)
			this.rapidChangeTimer = null
		}

		if (this.valueChangedFlag) {
			this.input.dispatchEvent(new CustomEvent('change', { bubbles: true, detail: { isTrigger: true } }))
			this.valueChangedFlag = false
		}
	}
}
