import { Control } from '@peckadesign/pd-naja/dist/types'

type ScrollDirection = 'up' | 'down'

class HeaderControl implements Control {
	private selector: string
	private toggleClass: string

	private handler: any

	private headerElement: HTMLElement | null = null
	private mainElement: HTMLElement | null = null

	private currentScrollTop: number = 0
	private prevScrollTop: number = 0
	private scrollTopOnDirectionChange: number = 0
	private scrollDirection: ScrollDirection = 'down'

	private downThreshold: number = 200
	private upThreshold: number = 100

	public constructor(selector: string = '.js-header', toggleClass: string = 'js-header--reduced') {
		this.selector = selector
		this.toggleClass = toggleClass
		this.handler = this.handleScroll.bind(this)

		const mediaQuery = window.matchMedia('(min-width: 768px)')

		mediaQuery.addEventListener('change', (event) => {
			if (event.matches) {
				window.removeEventListener('scroll', this.handler)
				this.headerElement?.classList.remove(this.toggleClass)
			} else {
				window.addEventListener('scroll', this.handler, { passive: true })
			}
		})

		mediaQuery.dispatchEvent(new CustomEvent('change'))
	}

	public initialize(): void {
		this.headerElement = document.querySelector<HTMLElement>(this.selector)
		this.mainElement = document.querySelector<HTMLElement>('main')
	}

	private handleScroll(): void {
		if (!this.headerElement || !this.mainElement) {
			return
		}

		let reduceHeader = false
		let changeState = false

		this.prevScrollTop = this.currentScrollTop
		this.currentScrollTop = window.scrollY

		// Setup scroll direction
		if (this.prevScrollTop < this.currentScrollTop) {
			if (this.scrollDirection === 'up') {
				this.scrollTopOnDirectionChange = window.scrollY
			}
			this.scrollDirection = 'down'
		} else {
			if (this.scrollDirection === 'down') {
				this.scrollTopOnDirectionChange = window.scrollY
			}
			this.scrollDirection = 'up'
		}

		const directionalScrollAmount = Math.abs(window.scrollY - this.scrollTopOnDirectionChange)
		const mainBoundingRect = this.mainElement.getBoundingClientRect()

		// Hide after scrolling down at least 200px and the `main` element is on top of the viewport or above
		if (this.scrollDirection === 'down' && directionalScrollAmount > this.downThreshold && mainBoundingRect.top <= 0) {
			reduceHeader = true
			changeState = true
		}

		// Show after scrolling up at least 100px, or when the `main` element is below the top of the viewport (this
		// also solves reaching the top of the page)
		if (mainBoundingRect.top > 0 || (this.scrollDirection === 'up' && directionalScrollAmount > this.upThreshold)) {
			reduceHeader = false
			changeState = true
		}

		if (!changeState) {
			return
		}

		this.headerElement.classList.toggle(this.toggleClass, reduceHeader)
	}
}

export default new HeaderControl()
