import { Control } from '@peckadesign/pd-naja/dist/types'
import naja from 'naja'

class LazyloadOnPageLoadControl implements Control {
	public initialize(context: Element | Document): void {
		const loadElements = context.querySelectorAll<HTMLElement>('[data-lazyload-on-page-load]')

		loadElements.forEach((element) => this.loadElement(element))
	}

	private loadElement(element: HTMLElement): void {
		const url: string = element.dataset.lazyloadOnPageLoad!
		const retryElement = element.querySelector<HTMLElement>('.js-lazyload__retry') || undefined

		this.makeRequest(url, retryElement)

		retryElement?.addEventListener('click', () => {
			this.toggleRetryElement(retryElement, false)
			this.makeRequest(url, retryElement)
		})
	}

	private makeRequest(url: string, retryElement?: HTMLElement): void {
		naja
			.makeRequest('get', url, null, {
				history: false
			})
			.catch(() => {
				if (!retryElement) {
					return
				}

				this.toggleRetryElement(retryElement, true)
			})
	}

	private toggleRetryElement(element: HTMLElement, isVisible: boolean): void {
		element.classList.toggle('opacity-0', !isVisible)
		element.ariaHidden = isVisible ? 'false' : 'true'
	}
}

export default new LazyloadOnPageLoadControl()
