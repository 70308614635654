import { HTMLFormFieldElement, PdFormsAjaxArg } from '@/js/UI/naja/PdFormsAjaxValidationExtension'
import { Nette } from '../../validation'

export function emailValidator(elem: HTMLFormFieldElement, arg: PdFormsAjaxArg, val: string, value: string) {
	return Nette.validators.PdFormsRules_ajax(
		elem,
		arg,
		val,
		value,
		'SuperzooWebAppOrderModuleModelPersonalDeliveryMailValidatorRule_validate'
	)
}
