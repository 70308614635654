export const waitForElementAnimations = (element: Element | null, callback: () => void): void => {
	const animations = element?.getAnimations({ subtree: true }) || []

	if (animations.length === 0) {
		callback()
		return
	}

	Promise.all(animations.map((animation) => animation.finished)).then(() => callback())
}
