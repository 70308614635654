import { Control } from '@peckadesign/pd-naja/dist/types'
import { CommonOptions, ResponsiveOptions, TinySliderInfo, tns } from 'tiny-slider/src/tiny-slider'
import { TinySliderInstance, TinySliderSettings } from 'tiny-slider'
import React from 'jsx-dom'
import { deepMerge } from 'collapsable.js'

interface HTMLCarouselElement extends HTMLElement {
	carousel: TinySliderInstance
	beforeUnloadHandler?: any
}

const breakpoints = {
	xs: '375px',
	sm: '576px',
	md: '768px',
	lg: '992px',
	xl: '1280px'
} as const

type Breakpoint = keyof typeof breakpoints

class CarouselControl implements Control {
	private mediaQueries: Partial<Record<Breakpoint, MediaQueryList>> = {}
	private carouselsPerMedia: Record<Breakpoint, HTMLElement[]>

	public constructor() {
		this.carouselsPerMedia = {} as Record<Breakpoint, HTMLElement[]>

		Object.keys(breakpoints).forEach((breakpoint) => {
			this.carouselsPerMedia[breakpoint as Breakpoint] = []
		})
	}

	public initialize(context: Element | Document): void {
		const carousels = context.querySelectorAll<HTMLElement>('.js-carousel')

		carousels.forEach((carouselWrapper: HTMLElement) => {
			const breakpoint = carouselWrapper.dataset.carouselMinBreakpoint

			if (breakpoint === undefined) {
				this.initializeCarousel(carouselWrapper)
			} else {
				this.initializeResponsiveCarousel(breakpoint as Breakpoint, carouselWrapper)
			}
		})
	}

	public destroy(context: Element) {
		const carousels = context.querySelectorAll<HTMLElement>('.js-carousel')

		carousels.forEach((carouselWrapper: HTMLElement) => {
			Object.keys(breakpoints).forEach((key) => {
				const breakpoint: Breakpoint = key as Breakpoint
				const index = this.carouselsPerMedia[breakpoint].indexOf(carouselWrapper)

				if (index > -1) {
					this.carouselsPerMedia[breakpoint].splice(index, 1)
				}
			})
		})
	}

	private handleMediaQueryChange(event: MediaQueryListEvent, breakpoint: Breakpoint): void {
		this.carouselsPerMedia[breakpoint].forEach((carouselWrapper: HTMLElement) => {
			if (event.matches) {
				this.initializeCarousel(carouselWrapper)
			} else if ('carousel' in carouselWrapper) {
				this.destroyCarousel(carouselWrapper as HTMLCarouselElement)
			}
		})
	}

	private getMediaQueriesList(breakpoint: Breakpoint): MediaQueryList {
		if (this.mediaQueries[breakpoint] === undefined) {
			const mediaQuery = window.matchMedia(`(min-width: ${breakpoints[breakpoint]}`)

			mediaQuery.addEventListener('change', (event) => {
				this.handleMediaQueryChange(event, breakpoint)
			})

			this.mediaQueries[breakpoint] = mediaQuery
		}

		return this.mediaQueries[breakpoint] as MediaQueryList
	}

	private initializeResponsiveCarousel(breakpoint: Breakpoint, carouselWrapper: HTMLElement) {
		this.carouselsPerMedia[breakpoint].push(carouselWrapper)

		if (this.getMediaQueriesList(breakpoint).matches) {
			this.initializeCarousel(carouselWrapper, breakpoint)
		}
	}

	private initializeCarousel(carouselWrapper: HTMLElement, breakpoint?: Breakpoint): void {
		const cacheIndexStorageKey = `tns-${carouselWrapper.id}-cachedIndex`
		const carouselElement = carouselWrapper.querySelector<HTMLElement>('.js-carousel__carousel')
		const itemsCount = carouselElement ? carouselElement.querySelectorAll('.js-carousel__item').length : 0
		const dataOptions = JSON.parse(carouselWrapper.dataset.carousel || '{}')

		if (!carouselElement || itemsCount <= (dataOptions.items || 1)) {
			return
		}

		const syncId = carouselWrapper.dataset.carouselSync
		const buildControls = dataOptions.controls !== false && dataOptions.controlsContainer === undefined
		const buildNav = dataOptions.nav !== false && dataOptions.navContainer === undefined

		const defaultOptions: TinySliderSettings = {
			speed: 300, // this is the library's default value, but explicitly stating it here, we can be sure it is always defined in `options`
			container: carouselElement,
			controlsContainer: buildControls
				? this.buildControlsContainer(carouselWrapper, carouselElement, breakpoint)
				: false,
			navContainer: buildNav ? this.buildNavContainer(carouselWrapper, itemsCount) : false,
			loop: false,
			startIndex: parseInt(localStorage.getItem(cacheIndexStorageKey) || '0')
		}

		const options: TinySliderSettings = deepMerge({}, defaultOptions, dataOptions)

		const instance = tns(options)

		const carouselWrapperCarousel = carouselWrapper as HTMLCarouselElement
		carouselWrapperCarousel.carousel = instance

		const targetSyncWrapper = syncId ? document.getElementById(syncId) : null
		if (targetSyncWrapper) {
			// We have to wait with the synchonisation until both carousels have changed their indexes.
			instance.events.on('indexChanged', (originInfo) => {
				// If `triggerSync` data is set, the `indexChanged` has been triggered manually and so it should not
				// cause another `syncCarousel`.
				if (carouselWrapper.dataset.triggerSync) {
					delete carouselWrapper.dataset.triggerSync
					return
				}

				targetSyncWrapper.dataset.triggerSync = 'true'

				setTimeout(() => {
					this.syncCarousel(originInfo, targetSyncWrapper)
				}, 0)
			})
		}

		if (carouselWrapper.classList.contains('js-carousel--save-index')) {
			carouselWrapperCarousel.beforeUnloadHandler = () => {
				localStorage.setItem(cacheIndexStorageKey, String(instance.getInfo().index))
			}
			window.addEventListener('beforeunload', carouselWrapperCarousel.beforeUnloadHandler)
		}

		// Wait until
		setTimeout(() => {
			carouselWrapper.classList.remove('js-carousel--not-initialized')
		}, options.speed)
	}

	private destroyCarousel(carouselWrapper: HTMLCarouselElement) {
		if (carouselWrapper.beforeUnloadHandler) {
			window.removeEventListener('beforeunload', carouselWrapper.beforeUnloadHandler)
			delete carouselWrapper.beforeUnloadHandler
		}

		carouselWrapper.carousel.destroy()

		carouselWrapper
			.querySelectorAll<HTMLElement>('.js-carousel__controls, .js-carousel__nav')
			.forEach((element) => (element.hidden = true))
	}

	private buildControlsContainer(
		carouselWrapper: HTMLElement,
		carouselElement: HTMLElement,
		breakpoint?: Breakpoint
	): HTMLElement {
		const controls = carouselWrapper.querySelector<HTMLElement>('.js-carousel__controls')

		if (controls) {
			controls.hidden = false
			return controls
		}

		const customControls: Element = (
			<p class={['m-0', breakpoint ? `max-${breakpoint}:hidden` : null, 'js-carousel__controls']}>
				<button type="button" class="btn btn--paging">
					<i class="btn__icon icon icon--chevron-left" aria-hidden="true"></i>
					<span class="btn__inner sr-only">Předchozí</span>
				</button>
				<button type="button" class="btn btn--paging">
					<span class="btn__inner sr-only">Další</span>
					<i class="btn__icon icon icon--chevron-right ml-px" aria-hidden="true"></i>
				</button>
			</p>
		)

		carouselElement.insertAdjacentElement('beforebegin', customControls)

		return customControls as HTMLElement
	}

	private buildNavContainer(carouselWrapper: HTMLElement, itemsCount: number): HTMLElement {
		const nav = carouselWrapper.querySelector<HTMLElement>('.js-carousel__nav')

		if (nav && nav.childElementCount === itemsCount) {
			nav.hidden = false
			return nav
		}

		nav?.replaceChildren()

		const navText = carouselWrapper.dataset.carouselNavText || ''
		const customNav: Element = nav || (
			<p class="flex justify-center mt-1 text-primary-500 hidden:hidden js-carousel__nav"></p>
		)

		for (let i = 0; i < itemsCount; i++) {
			customNav.appendChild(
				<button type="button" class="w-2.5 aspect-square opacity-50 transition js-carousel__page" style="display: none">
					<i class="icon icon--paw inline-block transform rotate-30 text-sm" aria-hidden="true"></i>
					<span class="sr-only">
						{navText} {i + 1}
					</span>
				</button>
			)
		}

		carouselWrapper.insertAdjacentElement('beforeend', customNav)

		return customNav as HTMLElement
	}

	private syncCarousel(originInfo: TinySliderInfo, targetCarouselWrapper: HTMLElement): void {
		if (targetCarouselWrapper.dataset.triggerSync === undefined || !('carousel' in targetCarouselWrapper)) {
			return
		}

		const targetCarousel = (targetCarouselWrapper as HTMLCarouselElement).carousel
		const targetInfo = targetCarousel.getInfo()

		// The `displayIndex` is missing in the library types, so the ts ignore statement is needed.
		// We need to use `displayIndex` because the index may overflow the slideCount. This is probably due to
		// `loop === true` and `rewind === false` options and cloned items. Since one of the carousels may not have
		// these settings (or may be `mode === 'gallery'` where cloning doesn't happen), the indexes may be different
		// even though displayIndexes are the same.
		// @ts-ignore
		const originIndex = originInfo.displayIndex - 1
		// @ts-ignore
		const targetIndex = targetInfo.displayIndex - 1

		// If the indexes are the same, we don't need to synchronise the carousels. This is necessary because of the
		// two-way synchronisation of the carousels.
		if (targetIndex === originIndex) {
			return
		}

		const targetOptions = JSON.parse(targetCarouselWrapper.dataset.carousel || '{}') as TinySliderSettings
		const rewindOption = this.getCarouselOption(targetOptions, 'rewind')

		// When on the last slide and clicking next, the index may overflow zero-based indexing, so we need to normalize
		// it.
		let goToIndex: number | 'next' | 'prev' = originInfo.index >= originInfo.slideCount ? originIndex : originInfo.index

		if (rewindOption === true) {
			targetCarousel.goTo(goToIndex)
			return
		}

		// We want to keep the infinite loop, so instead of just calling `goTo` with index, we may need to change
		// `goToIndex` to the next or prev keywords. When checking for 'next', we can use the overflow described above.
		// When  checking for 'prev', we also need to check for edge values. For safety reasons (and possible bug fixing
		// later in the plugin), we also check for edge values on the 'next' click.
		if (
			originInfo.index === originInfo.indexCached + 1 ||
			(originInfo.index === 0 && (originInfo.indexCached + 1) % originInfo.slideCount === 0)
		) {
			goToIndex = 'next'
		} else if (
			originInfo.index + 1 === originInfo.indexCached ||
			(originInfo.indexCached === 0 && (originInfo.index + 1) % originInfo.slideCount === 0)
		) {
			goToIndex = 'prev'
		}

		targetCarousel.goTo(goToIndex)
	}

	private getCarouselOption(options: TinySliderSettings, optionName: keyof TinySliderSettings): any {
		let optionValue = options[optionName]

		// Same value as in tiny-slider implementation
		const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

		if (!options.responsive) {
			return optionValue
		}

		const responsiveKeys = Object.keys(options.responsive).map((key) => parseInt(key))
		responsiveKeys.sort((a, b) => a - b)
		responsiveKeys.forEach((breakpoint) => {
			const currentOptions = (options.responsive as ResponsiveOptions)[breakpoint]

			if (windowWidth >= breakpoint && optionName in currentOptions) {
				optionValue = currentOptions[optionName as keyof CommonOptions]
			}
		})

		return optionValue
	}
}

export default new CarouselControl()
