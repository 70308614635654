import { Control } from '@peckadesign/pd-naja/dist/types'
import Collapsable from 'collapsable.js'
import { CollapsableEvent, CollapsableOptions } from 'collapsable.js/dist/Collapsable'
import { HTMLCollapsableItem } from 'collapsable.js/dist/CollapsableItem'
import { DeepPartial } from 'collapsable.js/dist/utils'

type CollapsableGroup = {
	selector: string
	offTapClose: boolean
	options?: DeepPartial<CollapsableOptions>
}

class CollapsableControl implements Control {
	private offTapClose: HTMLCollapsableItem[] = []
	private groups: CollapsableGroup[] = [
		{
			selector:
				'.js-collapsable:not(' +
				'	.js-collapsable--tabs,' +
				'	.js-collapsable--accordion,' +
				'	.js-collapsable--off-tap-close,' +
				'	[data-collapsable-overlay]' +
				')',
			offTapClose: false
		},
		{
			selector: '.js-collapsable-tabs .js-collapsable--tabs',
			offTapClose: false,
			options: {
				accordion: true,
				collapsableAll: false,
				externalLinks: {
					preventDefault: true
				}
			}
		},
		{
			selector: '.js-collapsable-accordion .js-collapsable--accordion',
			offTapClose: false,
			options: {
				accordion: true
			}
		},
		{
			selector: '.js-collapsable--off-tap-close, [data-collapsable-overlay]',
			offTapClose: true
		}
	]

	public constructor() {
		this.attachHandlers()
	}

	public initialize(context: Element | Document): void {
		this.purgeOffTapClose()

		this.groups.forEach((group) => {
			const collapsableElements = context.querySelectorAll<HTMLElement>(
				`:where(${group.selector}):not([data-collapsable-skip-init])`
			)

			if (collapsableElements.length === 0) {
				return
			}

			// If off tapping should close, we need to initialize items as separate Collapsable, otherwise
			// when `collapsableElements.length > 1`, off tap would close all items regardless of clicked target.
			if (group.offTapClose) {
				collapsableElements.forEach((element) => {
					const collapsable = new Collapsable(element, group.options)

					this.initializeElement(element as HTMLCollapsableItem, collapsable)
					this.offTapClose.push(element as HTMLCollapsableItem)
				})
			} else {
				const collapsable = new Collapsable(collapsableElements, group.options)
				collapsableElements.forEach((element) => {
					this.initializeElement(element as HTMLCollapsableItem, collapsable)
				})
			}
		})
	}

	private initializeElement(element: HTMLCollapsableItem, collapsable: Collapsable): void {
		this.responsiveDefaultExpanded(element)

		element.querySelectorAll(`button.${collapsable.options.classNames.interactiveElement} a`).forEach((anchor) => {
			anchor.addEventListener('click', (event) => event.stopPropagation())
		})

		if (element.dataset.collapsableHasAutofocus !== undefined) {
			element.addEventListener('expanded.collapsable', this.handleAutofocus.bind(this))
		}

		if (element.dataset.collapsableScrollIntoView !== undefined) {
			element.addEventListener('expanded.collapsable', this.handleScrollIntoView.bind(this))
		}
	}

	private responsiveDefaultExpanded(element: HTMLCollapsableItem): void {
		const defaultExpandedMedia = element.dataset.defaultExpandedMedia

		if (!defaultExpandedMedia) {
			return
		}

		const mediaQuery = window.matchMedia(defaultExpandedMedia)

		if (!mediaQuery.matches) {
			return
		}

		const collapsableEvent = new CustomEvent('init.collapsable', { bubbles: true })
		element.collapsableItem.expand(collapsableEvent, null, false)
	}

	private attachHandlers(): void {
		// Off tap close, we're listening during the capture phase, so this is handled before the overlay handlers.
		document.body.addEventListener('click', this.handleOffTapClose.bind(this), { capture: true })

		// Overlay
		document.body.addEventListener('expand.collapsable', this.handleOverlayClass.bind(this))
		document.body.addEventListener('collapse.collapsable', this.handleOverlayClass.bind(this))

		// Prevent collapse
		document.body.addEventListener('collapse.collapsable', this.checkCollapsability.bind(this))

		// Scroll to view
		document.body.addEventListener('expanded.collapsable', this.scrollToView.bind(this))
	}

	private handleOverlayClass(event: CollapsableEvent): void {
		const overlayClass = event.target && (event.target as HTMLElement).dataset.collapsableOverlay

		if (overlayClass) {
			const action = event.type === 'expand.collapsable' ? 'add' : 'remove'

			document.body.classList[action](overlayClass)
		}
	}

	private checkCollapsability(event: CollapsableEvent): void {
		if (event.target && (event.target as HTMLElement).classList.contains('js-collapsable--cannot-collapse')) {
			event.preventDefault()
		}
	}

	private scrollToView(event: CollapsableEvent): void {
		if ((event.target as HTMLElement).classList.contains('js-collapsable--scroll-to-view')) {
			setTimeout(() => {
				;(event.target as HTMLElement).scrollIntoView()
			}, 300)
		}
	}

	private purgeOffTapClose(): void {
		this.offTapClose = this.offTapClose.filter((collapsableItem) => collapsableItem.isConnected)
	}

	private handleOffTapClose(event: Event): void {
		if (!event.target) {
			return
		}

		const target = event.target as HTMLElement

		this.offTapClose.forEach((element: HTMLCollapsableItem) => {
			const extLinks = element.collapsableItem.collapsable
				.getExtLinkById(element.id)
				.filter((extLink) => extLink.extLink.contains(target))

			if (element.contains(target) || extLinks.length) {
				return
			}

			element.collapsableItem.collapsable.collapseAll()
		})
	}

	private handleAutofocus(event: Event): void {
		const collapsableItemElement = event.target as HTMLCollapsableItem
		const autofocus = collapsableItemElement.querySelector<HTMLInputElement>('[autofocus]')

		autofocus?.focus()
	}

	public handleScrollIntoView(event: Event): void {
		const collapsableItemElement = event.target as HTMLCollapsableItem
		collapsableItemElement.collapsableItem.boxElements[0].scrollIntoView({
			block: 'nearest'
		})
	}
}

export default new CollapsableControl()
