type ScrollbarWidthObject = {
	standard: number
	thin: number
}

class ScrollbarWidth {
	private readonly element: HTMLElement
	private _width: ScrollbarWidthObject

	public constructor() {
		this.element = document.createElement('div')

		this.prepareDOM()
		this._width = this.measure()

		const observer = new ResizeObserver(() => {
			this.updatewidth()
		})
		observer.observe(this.element)
	}

	public get width(): number {
		return this._width.standard
	}

	public get widthThin(): number {
		return this._width.thin
	}

	private measure(): ScrollbarWidthObject {
		const standard = this.element.offsetWidth - this.element.clientWidth

		this.element.style.scrollbarWidth = 'thin'
		const thin = this.element.offsetWidth - this.element.clientWidth
		this.element.style.scrollbarWidth = 'auto'

		return {
			standard,
			thin
		}
	}

	private updatewidth(): void {
		this._width = this.measure()
		document.documentElement.style.setProperty(`--scrollbar-width`, `${this._width.standard}px`)
		document.documentElement.style.setProperty(`--scrollbar-width-thin`, `${this._width.thin}px`)
		document.documentElement.classList.toggle('non-overlay-scrollbar', this._width.standard > 0)
	}

	private prepareDOM(): void {
		this.element.style.position = 'absolute'
		this.element.style.top = '0px'
		this.element.style.left = '0px'
		this.element.style.visibility = 'hidden'
		this.element.style.width = '200px'
		this.element.style.height = '150px'
		this.element.style.overflow = 'scroll'

		document.body.appendChild(this.element)
	}
}

export default new ScrollbarWidth()
