import Collapsable from 'collapsable.js'
import { CollapsableEvent, CollapsableOptions } from 'collapsable.js/dist/Collapsable'
import { CollapsableItem, HTMLCollapsableItem } from 'collapsable.js/dist/CollapsableItem'
import { DeepPartial } from 'collapsable.js/dist/utils'

export class MainMenu {
	private currentLevel: number = 1

	private media: MediaQueryList | undefined

	public static readonly menuSelector = '.js-main-menu'
	private static readonly itemSelector = '.js-main-menu-item'
	private static readonly itemClassName = MainMenu.itemSelector.substring(1)

	private readonly options: DeepPartial<CollapsableOptions> = {
		accordion: true,

		control: `${MainMenu.itemSelector}__control:not(:scope ${MainMenu.itemSelector} ${MainMenu.itemSelector}__control)`,
		box: `${MainMenu.itemSelector}__box:not(:scope ${MainMenu.itemSelector} ${MainMenu.itemSelector}__box)`,
		classNames: {
			collapsed: `${MainMenu.itemClassName}--collapsed`,
			expanded: `${MainMenu.itemClassName}--expanded`
		}
	}

	private scrollTopRestoreTimer: ReturnType<typeof setTimeout> | undefined = undefined
	private cachedScrollTop: number | undefined = undefined

	private mainMenuCollapsableElement: HTMLElement
	private menuElement: HTMLElement
	private rootItems: NodeListOf<HTMLCollapsableItem>
	private nestedItems: Map<HTMLCollapsableItem, NodeListOf<HTMLCollapsableItem>>

	private mainMenuCollapsableItem: CollapsableItem
	private onlyDesktopCollapsableItems: CollapsableItem[]

	private rootCollapsable: Collapsable
	private nestedCollapsable: Map<CollapsableItem, Collapsable> = new Map()

	public constructor(menuElement: HTMLElement) {
		const mainMenuCollapsableElement = document.querySelector<HTMLElement>('.js-collapsable--main-menu')

		this.menuElement = menuElement
		this.rootItems = this.menuElement.querySelectorAll<HTMLCollapsableItem>(
			`${MainMenu.itemSelector}--root:not(${MainMenu.itemSelector}--lg)`
		)

		if (!mainMenuCollapsableElement || !this.menuElement || !this.rootItems) {
			throw new Error('MainMenu: Missing some of the elements for the menu.')
		}

		this.mainMenuCollapsableElement = mainMenuCollapsableElement
		this.nestedItems = this.getNestedItems()

		// Initialize Collapsable
		const mainMenuCollapsable = new Collapsable(mainMenuCollapsableElement, {})
		this.mainMenuCollapsableItem = mainMenuCollapsable.items[0]

		this.rootCollapsable = new Collapsable(this.rootItems, this.options)

		this.nestedItems.forEach((nestedItems, rootItem) => {
			this.nestedCollapsable.set(rootItem.collapsableItem, new Collapsable(nestedItems, this.options))
		})

		// Setup responsive behavior
		this.onlyDesktopCollapsableItems = this.rootCollapsable.items.filter(
			(item) => item.element.dataset.collapsableSkipOnMobile !== undefined
		)

		this.initializeResponsive()

		// Event handlers
		document.body.addEventListener('click', this.handleOffTapClose.bind(this), { capture: true })

		this.menuElement.addEventListener('expand.collapsable', this.handleParentCollapsable.bind(this))
		this.menuElement.addEventListener('expand.collapsable', this.handleLevelChange.bind(this))
		this.menuElement.addEventListener('collapse.collapsable', this.handleLevelChange.bind(this))

		// Handle top notices bar
		mainMenuCollapsableElement.addEventListener('expand.collapsable', this.maximazeMenuHeight.bind(this))
		mainMenuCollapsableElement.addEventListener('collapsed.collapsable', this.restoreScroll.bind(this))

		const siteSearchForm = mainMenuCollapsableElement.querySelector('.js-site-search')
		siteSearchForm?.addEventListener('show.suggest', this.maximazeMenuHeight.bind(this))
		siteSearchForm?.addEventListener('hide.suggest', this.restoreScroll.bind(this))
	}

	private getNestedItems(): Map<HTMLCollapsableItem, NodeListOf<HTMLCollapsableItem>> {
		const nestedItems: Map<HTMLCollapsableItem, NodeListOf<HTMLCollapsableItem>> = new Map()

		this.rootItems.forEach((rootItem) => {
			const currentNestedItems = rootItem.querySelectorAll<HTMLCollapsableItem>(`${MainMenu.itemSelector}`)

			if (currentNestedItems.length) {
				nestedItems.set(rootItem, currentNestedItems)
			}
		})

		return nestedItems
	}

	// Some nested items are forced by CSS to be root items on mobile. When these are expanded, we need to manually
	// expand their parent as well.
	private handleParentCollapsable(event: CollapsableEvent): void {
		const currentCollapsable = (event.target as HTMLCollapsableItem).collapsableItem.collapsable
		const parentCollapsableItem = this.getNestedCollapsableParentItem(currentCollapsable)

		// On the desktop, nested items have `collapsableAll` set to `false`, so are manually expanded on media change.
		// This should not trigger the parent to open.
		if (parentCollapsableItem === undefined || event.detail.data?.skipHandleParent) {
			return
		}

		parentCollapsableItem.expand(event, null, true)
	}

	private initializeResponsive(): void {
		this.media = window.matchMedia('(min-width: 992px)')
		this.media.addEventListener('change', (event) => this.handleMediaQueryChange(event.matches))

		this.handleMediaQueryChange(this.media.matches)
	}

	private handleOffTapClose(event: Event): void {
		if (!event.target || this.menuElement.contains(event.target as HTMLElement)) {
			return
		}

		this.rootCollapsable.collapseAll()

		// When on mobile, we also have to close the main menu itself
		if (!this.media || this.media.matches) {
			return
		}

		const interactedMainMenuControlElements = this.mainMenuCollapsableItem.controlElements.filter((control) =>
			control.contains(event.target as HTMLElement)
		)

		if (interactedMainMenuControlElements.length === 0) {
			this.mainMenuCollapsableItem.collapse(event, null, true)
		}
	}

	// Initialize / destroy desktop only collapsable and handle `nestedCollapsable`, which cannot be closed all at once
	// on the desktop.
	private handleMediaQueryChange(isDesktop: boolean): void {
		this.setNestedCollapsableCollapsableAll(!isDesktop)

		this.handleConditionalCollapsable([this.mainMenuCollapsableItem], isDesktop)
		this.handleConditionalCollapsable(this.onlyDesktopCollapsableItems, !isDesktop)

		if (!isDesktop) {
			this.expandMainMenuIfNeeded()
		}
	}

	private setNestedCollapsableCollapsableAll(collapsableAll: boolean): void {
		this.nestedCollapsable.forEach((nestedCollapsable, parentCollapsable) => {
			nestedCollapsable.options.collapsableAll = collapsableAll

			if (collapsableAll && !parentCollapsable.isExpanded) {
				nestedCollapsable.collapseAll({ skipHandleLevelChange: true })
			}

			if (!collapsableAll && nestedCollapsable.getExpanded().length === 0) {
				nestedCollapsable.items[0].expand(null, { skipHandleLevelChange: true, skipHandleParent: true }, true)
			}
		})
	}

	private expandMainMenuIfNeeded(): void {
		const expandedRootCollapsable = this.rootCollapsable.getExpanded()

		if (expandedRootCollapsable.length) {
			this.mainMenuCollapsableItem.expand(null, null, true)
		}
	}

	private getNestedCollapsableParentItem(nestedCollapsable: Collapsable): CollapsableItem | undefined {
		for (const [key, value] of this.nestedCollapsable.entries()) {
			if (value === nestedCollapsable) return key
		}
	}

	private handleLevelChange(event: CollapsableEvent): void {
		if (
			event.detail.data?.skipHandleLevelChange ||
			event.detail.collapsableEvent === null ||
			event.detail.collapsableEvent.type === 'init.collapsable'
		) {
			return
		}

		let level = this.currentLevel

		if (event.type === 'expand.collapsable') {
			level++
		} else {
			level--
		}

		this.menuElement.dataset.currentMobileLevel = String(Math.min(Math.max(level, 1), 2))
	}

	private handleConditionalCollapsable(collapsableItems: CollapsableItem[], forceVisible: boolean) {
		collapsableItems.forEach((item) => {
			item.boxElements.forEach((box) => {
				box.hidden = !forceVisible && !item.isExpanded
				box.ariaHidden = String(!forceVisible && !item.isExpanded)
			})

			item.controlElements.forEach((control) => {
				control.ariaExpanded = String(forceVisible || item.isExpanded)
			})
		})
	}

	private maximazeMenuHeight(event: Event): void {
		if (
			this.media?.matches ||
			(event.type === 'expand.collapsable' && this.mainMenuCollapsableElement !== event.target)
		) {
			return
		}

		clearTimeout(this.scrollTopRestoreTimer)

		const scrollingElement = document.scrollingElement || document.documentElement
		const minScroll = this.mainMenuCollapsableElement.offsetTop

		if (minScroll <= scrollingElement.scrollTop) {
			return
		}

		this.cachedScrollTop = scrollingElement.scrollTop
		scrollingElement.scrollTop = minScroll
	}

	private restoreScroll(event: Event): void {
		if (
			this.cachedScrollTop === undefined ||
			(event.type === 'collapsed.collapsable' && this.mainMenuCollapsableElement !== event.target)
		) {
			return
		}

		// When the suggestion box is closed by opening the menu, the scroll shouldn't be restored. So we postpone the
		// restoration of the scroll, and the `maximazeMenuHeight` can prevent it by clearing the timeout.
		this.scrollTopRestoreTimer = setTimeout(() => {
			const scrollingElement = document.scrollingElement || document.documentElement
			scrollingElement.scrollTop = this.cachedScrollTop!
			this.cachedScrollTop = undefined
		}, 0)
	}
}
