import AutoRefreshControl from '@/js/Controls/AutoRefreshControl'
import AutoSubmitControl from '@/js/Controls/AutoSubmitControl'
import BrandsFilterFocusControl from '@/js/Controls/BrandsFilterFocusControl'
import BrowserGeolocationControl from '@/js/Controls/BrowserGeolocationControl'
import CarouselControl from '@/js/Controls/CarouselControl'
import ChatControl from '@/js/Controls/ChatControl'
import CollapsableControl from '@/js/Controls/CollapsableControl'
import CopyButtonControl from '@/js/Controls/CopyButtonControl'
import CountdownControl from '@/js/Controls/CountdownControl'
import DailyFeedRationControl from '@/js/Controls/DailyFeedRationControl'
import DropdownControl from '@/js/Controls/DropdownControl'
import EventCallbackControl from '@/js/Controls/EventCallbackControl'
import FilterSelectOptionsControl from '@/js/Controls/FilterSelectOptionsControl'
import FormEnterControl from '@/js/Controls/FormEnterControl'
import FulltextControl from '@/js/Controls/FulltextControl'
import HeaderControl from '@/js/Controls/HeaderControl'
import HomepageAnimalsControl from '@/js/Controls/HomepageAnimalsControl'
import ImageUploadPreviewControl from '@/js/Controls/ImageUploadPreviewControl'
import InputAllowedCharactersControl from '@/js/Controls/InputAllowedCharactersControl'
import LazyloadOnPageLoadControl from '@/js/Controls/LazyloadOnPageLoadControl'
import LinkToTopControl from '@/js/Controls/LinkToTopControl'
import MainMenuControl from '@/js/Controls/MainMenuControl'
import NavBarHighlightControl from '@/js/Controls/NavBarHighlightControl'
import OnCheckFocusControl from '@/js/Controls/OnCheckFocusControl'
import ParameterExpandControl from '@/js/Controls/ParameterExpandControl'
import PhoneGroupControl from '@/js/Controls/PhoneGroupControl'
import PickupPointControl from '@/js/Controls/PickupPointControl'
import QuantityInputControl from '@/js/Controls/QuantityInputControl'
import QuantityDiscountControl from '@/js/Controls/QuantityDiscountControl'
import ScrollToActiveControl from '@/js/Controls/ScrollToActiveControl'
import SelectToLinkControl from '@/js/Controls/SelectToLinkControl'
import SliderControl from '@/js/Controls/SliderControl'
import SmsCodeVerificationControl from '@/js/Controls/SmsCodeVerificationControl'
import TooltipControl from '@/js/Controls/TooltipControl'
import TopNoticesBarControl from '@/js/Controls/TopNoticesBarControl'
import { ControlManager } from '@peckadesign/pd-naja'
import naja from 'naja'

const controlManager = new ControlManager(naja)

controlManager.addControlOnLoad(BrandsFilterFocusControl)
controlManager.addControlOnLoad(ChatControl)
controlManager.addControlOnLoad(CopyButtonControl)
controlManager.addControlOnLoad(CountdownControl)
controlManager.addControlOnLoad(FormEnterControl)
controlManager.addControlOnLoad(HeaderControl)
controlManager.addControlOnLoad(HomepageAnimalsControl)
controlManager.addControlOnLoad(LazyloadOnPageLoadControl)
controlManager.addControlOnLoad(LinkToTopControl)
controlManager.addControlOnLoad(MainMenuControl)
controlManager.addControlOnLoad(NavBarHighlightControl)
controlManager.addControlOnLoad(PickupPointControl)
controlManager.addControlOnLoad(TopNoticesBarControl)

controlManager.addControlOnLive(AutoSubmitControl)
controlManager.addControlOnLive(AutoRefreshControl)
controlManager.addControlOnLive(BrowserGeolocationControl)
controlManager.addControlOnLive(CarouselControl)
controlManager.addControlOnLive(CollapsableControl)
controlManager.addControlOnLive(DailyFeedRationControl)
controlManager.addControlOnLive(DropdownControl)
controlManager.addControlOnLive(EventCallbackControl)
controlManager.addControlOnLive(FilterSelectOptionsControl)
controlManager.addControlOnLive(FulltextControl)
controlManager.addControlOnLive(ImageUploadPreviewControl)
controlManager.addControlOnLive(InputAllowedCharactersControl)
controlManager.addControlOnLive(OnCheckFocusControl)
controlManager.addControlOnLive(ParameterExpandControl)
controlManager.addControlOnLive(PhoneGroupControl)
controlManager.addControlOnLive(QuantityInputControl)
controlManager.addControlOnLive(QuantityDiscountControl)
controlManager.addControlOnLive(ScrollToActiveControl)
controlManager.addControlOnLive(SelectToLinkControl)
controlManager.addControlOnLive(SliderControl)
controlManager.addControlOnLive(SmsCodeVerificationControl)
controlManager.addControlOnLive(TooltipControl)

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', () => controlManager.onLoad())
} else {
	controlManager.onLoad()
}
