import { pdForms } from '@/js/UI/validation'
import { hideSpinner, showSpinner } from '@peckadesign/pd-naja'
import { SpinnerPropsFn, SpinnerType, WithSpinner } from '@peckadesign/pd-naja/dist/types'
import {
	AbortEvent,
	BeforeEvent,
	CompleteEvent,
	ErrorEvent,
	Extension,
	Naja,
	StartEvent,
	SuccessEvent
} from 'naja/dist/Naja'

export type HTMLFormFieldElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLButtonElement

export type PdFormsExtensionData = {
	elem: HTMLFormFieldElement
	arg: PdFormsAjaxArg
	callback: any
	timeout?: ReturnType<typeof setTimeout>
	spinner?: Element
}

export type PdFormsAjaxArg = {
	ajaxUrl: string
	dependentInputs: Record<string, string>
	msg: Record<string, string>
	optional: boolean
}

declare module 'naja/dist/Naja' {
	interface Options {
		pdFormsValidation?: PdFormsExtensionData
	}
}

export class PdFormsAjaxValidationExtension implements Extension {
	public readonly timeout = 5000
	public readonly spinner?: SpinnerType
	public readonly getSpinnerProps?: SpinnerPropsFn

	public constructor(spinner: SpinnerType | undefined = undefined, getSpinnerProps: SpinnerPropsFn = undefined) {
		this.spinner = spinner
		this.getSpinnerProps = getSpinnerProps
	}

	public initialize(naja: Naja): void {
		naja.addEventListener('before', this.showSpinner.bind(this))
		naja.addEventListener('complete', this.hideSpinner.bind(this))

		naja.addEventListener('start', this.setTimeout.bind(this))

		naja.addEventListener('success', this.evaluateValidation.bind(this))
		naja.addEventListener('error', this.evaluateValidation.bind(this))
		naja.addEventListener('abort', this.evaluateValidation.bind(this))
	}

	private showSpinner(event: BeforeEvent): void {
		const { options } = event.detail

		if (!options.pdFormsValidation || !this.spinner) {
			return
		}

		const spinnerTarget = options.pdFormsValidation.elem.closest(
			`.pdforms-ajax-spinner--${options.pdFormsValidation.elem.id}`
		)

		if (spinnerTarget) {
			options.pdFormsValidation.spinner = showSpinner.call(this as WithSpinner, spinnerTarget)
		}
	}

	private hideSpinner(event: CompleteEvent): void {
		const { options } = event.detail

		if (!options.pdFormsValidation || !options.pdFormsValidation.spinner) {
			return
		}

		hideSpinner(options.pdFormsValidation.spinner)
	}

	private setTimeout(event: StartEvent): void {
		const { options, abortController } = event.detail

		if (!options.pdFormsValidation) {
			return
		}

		options.pdFormsValidation.timeout = setTimeout(() => {
			abortController.abort()
		}, this.timeout)
	}

	private evaluateValidation(event: SuccessEvent | AbortEvent | ErrorEvent): void {
		const { options } = event.detail

		if (!options.pdFormsValidation) {
			return
		}

		clearTimeout(options.pdFormsValidation.timeout)

		let payload, status

		if (event.type === 'success') {
			payload = (event as SuccessEvent).detail.payload
			status = payload.status || (payload.valid ? 'valid' : 'invalid')
		} else {
			status = 'timeout'
		}

		pdForms.ajaxEvaluate(
			options.pdFormsValidation.elem,
			options.pdFormsValidation.callback,
			status,
			payload,
			options.pdFormsValidation.arg
		)
	}
}
