export class SelectToLink {
	public static readonly selector = '.js-select-to-link'

	private select: HTMLSelectElement
	private links: HTMLAnchorElement[]

	public constructor(element: Element) {
		const select = element.querySelector<HTMLSelectElement>(`${SelectToLink.selector}__select`)
		const links = element.querySelectorAll<HTMLAnchorElement>(`${SelectToLink.selector}__link`)

		if (!select || !links.length) {
			throw new Error('SelectToLink: Missing select or link elements.')
		}

		this.select = select
		this.links = Array.from(links)

		this.select.addEventListener('change', this.handleSelect.bind(this))
	}

	private handleSelect(): void {
		const link = this.links.filter((link) => link.getAttribute('href') === this.select.value)

		if (link.length === 0) {
			return
		}

		link[0].click()
	}
}
