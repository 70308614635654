export class ParameterExpand {
	public static readonly selector = '.js-parameter'
	public static readonly className = ParameterExpand.selector.substring(1)

	private element: HTMLElement
	private control: HTMLElement
	private button: HTMLButtonElement

	public constructor(element: HTMLElement) {
		const control = element.querySelector<HTMLButtonElement>(`${ParameterExpand.selector}__control`)
		const button = element.querySelector<HTMLButtonElement>(`${ParameterExpand.selector}__button`)

		if (!control || !button) {
			throw new Error('ParameterExpand: Missing button element.')
		}

		this.element = element
		this.control = control
		this.button = button

		this.element.addEventListener('fulltextSearch', this.toggleControlVisibility.bind(this) as EventListener)
		this.element.addEventListener('fulltextReset', () => (this.control.hidden = false))

		this.button.addEventListener('click', this.toggleExpanded.bind(this))
	}

	private toggleExpanded(): void {
		this.element.classList.toggle(`${ParameterExpand.className}--expanded`)
	}

	private toggleControlVisibility(event: CustomEvent): void {
		this.control.hidden = event.detail.results.length <= 10
	}
}
