import { Control } from '@peckadesign/pd-naja/dist/types'
import naja from 'naja'

class AutoRefreshControl implements Control {
	private findElements(context: Element | Document): HTMLElement[]
	{
		let elements = Array.from(context.querySelectorAll<HTMLElement>('.js-auto-refresh'))

		if (elements.length === 0 && context instanceof HTMLElement && context.matches('.js-auto-refresh')) {
			elements = [context]
		}

		return elements
	}

	public initialize(context: Element | Document): void {
		this.findElements(context).forEach(element => this.initializeElement(element))
	}

	private initializeElement(element: HTMLElement): void
	{
		let interval = element.dataset.autoRefreshInterval
		let callback = element.dataset.autoRefreshUrl
		if (interval === undefined || callback === undefined) {
			return
		}

		element.dataset.autoRefreshTimerId = window.setTimeout(
			() => naja.makeRequest('get', callback, null, {history: false}),
			parseInt(interval, 10),
		).toString()
	}

	public destroy(context: Element): void
	{
		this.findElements(context).forEach(element => {
			if (element.dataset.autoRefreshTimerId !== undefined) {
				clearTimeout(parseInt(element.dataset.autoRefreshTimerId, 10))
			}
		})
	}
}

export default new AutoRefreshControl()
