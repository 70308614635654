import { Alert, AlertArrow, AlertProps, AlertVariant } from '@/js/UI/Components/Alert'
import { HTMLFormFieldElement } from '@/js/UI/naja/PdFormsAjaxValidationExtension'
import { pdForms, pdFormsAddMessage } from '@/js/UI/validation'
import { isDatasetFalsy } from '@peckadesign/pd-naja'
import naja from 'naja'

type Placeholder = {
	elem: HTMLElement
	isGlobal: boolean
}

export function addMessage(
	elem: HTMLFormFieldElement,
	message: string,
	type: string,
	isAjaxRuleMessage: boolean,
	escapeMessage: boolean
): void {
	if ((elem.dataset.pdformsMessage === 'alert' || type !== 'error') && message) {
		const placeholder = pdForms.getMessagePlaceholder(elem) as Placeholder

		if (!placeholder.elem) {
			return
		}

		let messageType: string = elem.dataset.pdformsMessageTypeError ?? type
		let showIcon: boolean = true
		let arrow: AlertArrow = null

		if (elem.dataset.pdformsMessageAlertArrow) {
			arrow =
				elem.dataset.pdformsMessageAlertArrow === 'off' ? null : (elem.dataset.pdformsMessageAlertArrow as AlertArrow)
		} else if (!placeholder.isGlobal) {
			arrow = 'top'
		}

		// If the `type` is JSON from backend, extract the `messageType` and `showIcon` properties from it
		try {
			;({ messageType, showIcon } = JSON.parse(type))
		} catch (e) {
			/* empty */
		}

		placeholder.elem.classList.add(`pdforms-${type}`)

		const alertProps: AlertProps = {
			variant: messageType as AlertVariant,
			tag: placeholder.isGlobal ? 'p' : 'label',
			arrow: arrow,
			componentClass: 'pdforms-message my-1 first:mt-0 last:mb-0',
			textContent: message
		}

		if (!showIcon) {
			alertProps.icon = null
		}

		if (isDatasetFalsy(elem, 'pdformsMessageAlertArrow')) {
			alertProps.arrow = undefined
		}

		const messageElement = Alert(alertProps)
		messageElement.setAttribute('data-elem', elem.name)

		if (isAjaxRuleMessage) {
			messageElement.setAttribute('data-ajax-rule', 'true')
		}

		if (elem.id) {
			messageElement.setAttribute(placeholder.isGlobal ? 'data-for' : 'for', elem.id)
		}

		placeholder.elem.insertAdjacentElement(
			placeholder.elem.getAttribute('data-pdforms-messages-prepend') ? 'afterbegin' : 'beforeend',
			messageElement
		)

		naja.uiHandler.bindUI(messageElement)
	} else {
		pdFormsAddMessage(elem, message, type, isAjaxRuleMessage, escapeMessage)
	}
}
