import { QuantityInput } from '@/js/App/QuantityInput'
import { Control } from '@peckadesign/pd-naja/dist/types'

class QuantityInputControl implements Control {
	public initialize(context: Element | Document) {
		const quantityInputs = context.querySelectorAll<HTMLElement>('.js-quantity-input')

		quantityInputs.forEach((quantityInput) => new QuantityInput(quantityInput))
	}
}

export default new QuantityInputControl()
