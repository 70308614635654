// The original TwemojiCountryFlags is too bug. We are creating custom subset, that includes only the flags we are
// interested in:
//   - 🇨🇿	https://emojipedia.org/flag-czechia    U+1F1E8 U+1F1FF
//   - 🇸🇰	https://emojipedia.org/flag-slovakia   U+1F1F8 U+1F1F0
//   - 🇱🇻	https://emojipedia.org/flag-latvia     U+1F1F1 U+1F1FB
//   - 🇵🇱	https://emojipedia.org/flag-poland     U+1F1F5 U+1F1F1
//   - 🇦🇹	https://emojipedia.org/flag-austria    U+1F1E6 U+1F1F9
//   - 🇩🇪	https://emojipedia.org/flag-germany    U+1F1E9 U+1F1EA
//   - 🇭🇺	https://emojipedia.org/flag-hungary    U+1F1ED U+1F1FA
//   - 🇺🇦	https://emojipedia.org/flag-ukraine    U+1F1FA U+1F1E6
//   - 🇪🇪	https://emojipedia.org/flag-estonia    U+1F1EA U+1F1EA
//   - 🇱🇹	https://emojipedia.org/flag-lithuania  U+1F1F1 U+1F1F9
//
// The following command can be used to subset the flags above:
//   `pyftsubset TwemojiCountryFlags.woff2 --no-subset-tables+=FFTM --output-file=TwemojiCountryFlagsSubset.woff2 --flavor=woff2 --unicodes=U+1F1E8,U+1F1FF,U+1F1F8,U+1F1F0,U+1F1F1,U+1F1FB,U+1F1F5,U+1F1F1,U+1F1E6,U+1F1F9,U+1F1E9,U+1F1EA,U+1F1ED,U+1F1FA,U+1F1FA,U+1F1E6,U+1F1EA,U+1F1EA,U+1F1F1,U+1F1F9`
//

import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill'
import TwemojiSubset from '@/webfonts/TwemojiCountryFlagsSubset.woff2'

polyfillCountryFlagEmojis('Twemoji Country Flags', TwemojiSubset)
