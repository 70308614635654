import collapsableControl from '@/js/Controls/CollapsableControl'
import { Control } from '@peckadesign/pd-naja/dist/types'

class TopNoticesBarControl implements Control {
	private static readonly selector = '.js-top-notices'

	private slideVisibilityDuration: number = 0
	private slideTransitionDuration: number = 0
	private slideCount: number = 0

	private element: HTMLElement | null = null
	private closeLink: HTMLElement | null = null

	public initialize(context: Element | Document): void {
		const topNoticesBar = context.querySelector<HTMLElement>(TopNoticesBarControl.selector)

		if (!topNoticesBar) {
			return
		}

		this.getCssProperties(topNoticesBar)

		if (!this.hasAllProperties()) {
			return
		}

		this.element = topNoticesBar

		this.closeLink = topNoticesBar.querySelector<HTMLElement>(`${TopNoticesBarControl.selector}__close`)
		this.closeLink?.addEventListener('click', this.closeBar.bind(this))

		const styleElement = document.createElement('style')
		styleElement.appendChild(document.createTextNode(this.generateRotateKeyframes()))

		document.head.appendChild(styleElement)
	}

	private closeBar(): void {
		if (!this.element) {
			return
		}

		this.element.ariaHidden = 'true'

		setTimeout(() => {
			this.element?.remove()
		}, 1000)
	}

	private getCssProperties(element: HTMLElement): void {
		const style = window.getComputedStyle(element)

		this.slideCount = parseInt(style.getPropertyValue('--notices-count'))
		this.slideVisibilityDuration = parseInt(style.getPropertyValue('--slide-visibility-duration'))
		this.slideTransitionDuration = parseInt(style.getPropertyValue('--slide-transition-duration'))
	}

	private hasAllProperties(): boolean {
		return Boolean(
			this.slideCount &&
				!isNaN(this.slideCount) &&
				!isNaN(this.slideVisibilityDuration) &&
				!isNaN(this.slideTransitionDuration)
		)
	}

	private generateRotateKeyframes() {
		const animationFramesCount = this.slideCount * 2 - 2
		const animationDuration = animationFramesCount * (this.slideVisibilityDuration + this.slideTransitionDuration)

		const percentageVisibility = (this.slideVisibilityDuration / animationDuration) * 100 // percentage of time for each frame
		const percentageTransition = (this.slideTransitionDuration / animationDuration) * 100 // percentage of time for each transition

		const keyframes: string[] = []

		const rotateLoop = (counter: number) => {
			if (counter > 0) {
				rotateLoop(counter - 1)

				const start = (counter - 1) * percentageVisibility + (counter - 1) * percentageTransition
				const stop = start + percentageVisibility

				let transformValue = ''
				if (counter <= this.slideCount) {
					transformValue = `translateY(${-1 * (counter - 1) * 100}%)`
				} else if (counter > this.slideCount) {
					transformValue = `translateY(${-1 * (animationFramesCount - counter + 1) * 100}%)`
				}

				keyframes.push(`\t${start.toFixed(2)}%, ${stop.toFixed(2)}% { transform: ${transformValue}; }\n`)
			}
		}

		rotateLoop(animationFramesCount)

		return `@keyframes rotate {\n${keyframes.join('')}}`
	}
}

export default new TopNoticesBarControl()
