import { Control } from '@peckadesign/pd-naja/dist/types'

class PhoneGroupControl implements Control {
	public static readonly selector = '.js-phone-group'

	public initialize(context: Element | Document): void {
		const phoneGroups = context.querySelectorAll<HTMLElement>(PhoneGroupControl.selector)

		phoneGroups.forEach((phoneGroup) => this.initializeGroup(phoneGroup))
	}

	private initializeGroup(element: HTMLElement): void {
		const phoneCodeInput = element.querySelector<HTMLSelectElement>(`${PhoneGroupControl.selector}__phone-code`)
		const phoneInput = element.querySelector<HTMLInputElement>(`${PhoneGroupControl.selector}__phone`)

		if (!phoneInput || !phoneCodeInput) {
			return
		}

		const phoneCodeValues = Array.from(phoneCodeInput.options)
			.map((option) => option.value)
			.filter((option) => option !== '')

		phoneInput.addEventListener('change', this.phoneChanged.bind(this, phoneInput, phoneCodeInput, phoneCodeValues))
		phoneInput.addEventListener('keyup', this.phoneChanged.bind(this, phoneInput, phoneCodeInput, phoneCodeValues))
	}

	private phoneChanged(
		phoneInput: HTMLInputElement,
		phoneCodeInput: HTMLSelectElement,
		phoneCodeValues: string[]
	): void {
		phoneCodeValues.forEach((option) => {
			if (phoneInput.value.substring(0, option.length) === option) {
				phoneCodeInput.value = option
				phoneInput.value = phoneInput.value.substring(option.length)

				phoneCodeInput.dispatchEvent(new Event('change', { bubbles: true }))
			}
		})
	}
}

export default new PhoneGroupControl()
