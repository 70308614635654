import { modal } from '@/js/App/PdModal/PdModal'
import { Control } from '@peckadesign/pd-naja/dist/types'
import naja from 'naja'

declare global {
	interface DocumentEventMap {
		pickupPointSelected: PickupPointSelectedEvent
	}

	interface HTMLElementEventMap {
		pickupPointSelected: PickupPointSelectedEvent
	}
}

export type PickupPointSelectedEvent = CustomEvent<{
	shipmentHash: string
	personalPickupId: string
	pickupPlaceId: string
}>

class PickupPointControl implements Control {
	private callbackUrl: string | undefined

	public constructor() {
		this.callbackUrl = document.body.dataset.pickupPointCallbackUrl
	}

	public initialize(): void {
		document.addEventListener('pickupPointSelected', this.onSelect.bind(this))
	}

	private onSelect(event: PickupPointSelectedEvent): void {
		if (modal.isOpen) {
			modal.close(event)
		}

		if (!this.callbackUrl) {
			return
		}

		const requestUrl = this.callbackUrl
			.replace('SHIPMENT_HASH', event.detail.shipmentHash)
			.replace('PERSONAL_PICKUP_ID', event.detail.personalPickupId)
			.replace('PICKUP_PLACE_ID', event.detail.pickupPlaceId)

		naja.makeRequest('get', requestUrl, null, {history: false})
	}
}

export default new PickupPointControl()
