import PdModalConfirm from '@/js/App/PdModal/PdModalConfirm'
import PdModal from '@peckadesign/pd-modal'
import { StoreEvent } from 'naja/dist/core/SnippetCache'
import { InteractionEvent } from 'naja/dist/core/UIHandler'
import { BeforeEvent, Extension, Naja } from 'naja/dist/Naja'

declare module 'naja/dist/Naja' {
	interface Options {
		pdModalConfirm?: boolean
	}
}

export class PdModalConfirmExtension implements Extension {
	private naja: Naja | undefined
	private modal: PdModal

	public constructor(modal: PdModal) {
		this.modal = modal
	}

	public initialize(naja: Naja): void {
		this.naja = naja

		naja.uiHandler.addEventListener('interaction', this.checkExtensionEnabled.bind(this), false)
		naja.addEventListener('before', this.hijackSpinner.bind(this))
	}

	private async checkExtensionEnabled(event: InteractionEvent): Promise<void> {
		const { element, originalEvent, options } = event.detail

		const shouldConfirm = element.getAttribute('data-confirm-content')
		const eventConfirmed = (originalEvent as CustomEvent).detail?.confirmed

		// If this extension is disabled for the request, or if the request has already been confirmed, just continue.
		if (!shouldConfirm || eventConfirmed) {
			options.pdModalConfirm = shouldConfirm !== null
			return
		}

		// As the confirmation is asynchronous, we must always stop the naja from executing the request.
		event.preventDefault()

		// Temporarily disable cache, so that the confirmation modal content isn't cached from `replaceInitialState` call.
		this.naja?.snippetCache.addEventListener('store', this.removeModalFromCache.bind(this), { once: true })

		// Then we wait for the user interaction with our custom confirm dialog.
		const confirmed = await PdModalConfirm.confirm(element as HTMLElement)

		// And if it is confirmed, we can trigger the same interaction on the original item, only this time with
		// a custom flag.
		if (confirmed) {
			element.dispatchEvent(
				new CustomEvent(originalEvent?.type || 'click', {
					bubbles: true,
					detail: {
						confirmed: true
					}
				})
			)
		}
	}

	private removeModalFromCache(event: StoreEvent): void {
		const { snippets, state } = event.detail

		// We want to prevent the confirmation modal to be cached during the `replaceInitialState` call. This could
		// potentially break, if the first interaction with confirmation dialog is also `data-naja-history="replace"`.
		if (state.cursor === 0) {
			delete snippets[this.modal.title.id]
			delete snippets[this.modal.content.id]
		}
	}

	private hijackSpinner(event: BeforeEvent): void {
		const { options } = event.detail

		if (!options.pdModalConfirm) {
			return
		}

		options.spinnerInitiator = undefined
		options.btnSpinnerInitiator = PdModalConfirm.acceptButton
	}
}
