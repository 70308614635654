import { HTMLTooltipButton } from '@/js/Controls/TooltipControl'
import { Control } from '@peckadesign/pd-naja/dist/types'
import { Instance } from 'tippy.js'

class CopyButtonControl implements Control {
	public static readonly selector = '.js-copy'

	public defaultIconClassList = ['icon--copy', 'text-primary-500']
	public successIconClassList = ['icon--success', 'text-green-300']

	public initialize(context: Element | Document): void {
		const buttons = context.querySelectorAll<HTMLButtonElement>(CopyButtonControl.selector)

		buttons.forEach((button) => button.addEventListener('click', this.handleButtonClick.bind(this)))
	}

	private handleButtonClick(event: MouseEvent): void {
		const button = event.currentTarget as HTMLTooltipButton
		const icon = button.querySelector<HTMLElement>('.icon')
		const text = button.dataset.copyText
		const tippyInstance = button._tippy as Instance | undefined

		if (!text) {
			return
		}

		navigator.clipboard
			.writeText(text)
			.then(() => {
				this.showSuccessState(tippyInstance, button, icon)
			})
			.catch(() => {})
	}

	private showSuccessState(
		tippyInstance: Instance | undefined,
		button: HTMLTooltipButton,
		icon: HTMLElement | null
	): void {
		const copiedTooltip = button.dataset.tooltipCopied

		icon?.classList.remove(...this.defaultIconClassList)
		icon?.classList.add(...this.successIconClassList)

		if (!copiedTooltip) {
			return
		}

		tippyInstance?.setContent(copiedTooltip)

		setTimeout(() => {
			this.restoreInitialState(tippyInstance, button, icon)
		}, 3000)
	}

	private restoreInitialState(
		tippyInstance: Instance | undefined,
		button: HTMLTooltipButton,
		icon: HTMLElement | null
	): void {
		tippyInstance?.setContent(button.dataset.tippyContent!)

		icon?.classList.remove(...this.successIconClassList)
		icon?.classList.add(...this.defaultIconClassList)
	}
}

export default new CopyButtonControl()
