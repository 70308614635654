import { DailyFeedRation } from '@/js/App/DailyFeedRation'
import { Control } from '@peckadesign/pd-naja/dist/types'

class DailyFeedRationControl implements Control {
	initialize(context: Element | Document): void {
		const elements = context.querySelectorAll<HTMLElement>(DailyFeedRation.selector)

		elements.forEach((element) => new DailyFeedRation(element))
	}
}

export default new DailyFeedRationControl()
