import { SelectToLink } from '@/js/App/SelectToLink'
import { Control } from '@peckadesign/pd-naja/dist/types'

class SelectToLinkControl implements Control {
	initialize(context: Element | Document): void {
		const selects = context.querySelectorAll(`${SelectToLink.selector}`)

		selects.forEach((select) => new SelectToLink(select))
	}
}

export default new SelectToLinkControl()
