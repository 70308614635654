import { HTMLCollapsableItem } from 'collapsable.js/dist/CollapsableItem'

export class Dropdown {
	public static readonly selector: string = '.js-dropdown'

	private element: HTMLCollapsableItem
	private value: HTMLElement
	private box: HTMLElement

	public constructor(element: HTMLElement) {
		const value = element.querySelector<HTMLElement>(`${Dropdown.selector}__value`)
		const box = element.querySelector<HTMLElement>('.js-collapsable__box')

		if (!value || !box || !('collapsableItem' in element)) {
			throw new Error('Dropdown: Missing element for value or not a collapsable element.')
		}

		this.element = element as HTMLCollapsableItem
		this.value = value
		this.box = box

		const links = this.box.querySelectorAll<HTMLAnchorElement>('a.ajax')

		links.forEach((link) => {
			link.addEventListener('click', (event: Event) => this.dropdownLinkClick(event, link))
		})
	}

	private dropdownLinkClick(event: Event, link: HTMLAnchorElement): void {
		this.value.textContent = link.dataset.dropdownValue || link.textContent
		this.element.collapsableItem.collapse(event, null, false)
	}
}
