import { HTMLFormFieldElement } from '@/js/UI/naja/PdFormsAjaxValidationExtension'
import { addMessage } from '@/js/UI/pdForms/addMessage'
import { personalDeliveryEmailValidatorCallback } from '@/js/UI/pdForms/callbacks/personalDeliveryEmailValidatorCallback'
import { emailValidator } from '@/js/UI/pdForms/validators/emailValidator'
import { PdFormsRules_ajax } from '@/js/UI/pdForms/validators/pdFormsRules_ajax'
import naja from 'naja'

// Load netteForms without initialization
export let Nette: any
window.Nette = Nette = require('nette-forms')

// Load custom validators
require('pd-forms/src/assets/validators/pdForms.validator.netteRuleProxy')
require('pd-forms/src/assets/validators/pdForms.validator.phone')

// Load pdForms
// eslint-disable-next-line @typescript-eslint/no-var-requires
export const pdForms = require('pd-forms/src/assets/pdForms')

pdForms.constants.MESSAGE_VALID = 'success'
pdForms.callbacks = {}
pdForms.naja = naja

Nette.validators.PdFormsRules_ajax = PdFormsRules_ajax
Nette.validators.SuperzooWebAppOrderModuleModelPersonalDeliveryMailValidatorRule_validate = emailValidator

pdForms.ajaxCallbacks.SuperzooWebAppOrderModuleModelPersonalDeliveryMailValidatorRule_validate =
	personalDeliveryEmailValidatorCallback

// The original method must be stored here, not in `addMessage.ts`, as this would result in `pdForms` being accessed
// before initialization.
export const pdFormsAddMessage: (
	elem: HTMLFormFieldElement,
	message: string,
	type: string,
	isAjaxRuleMessage: boolean,
	escapeMessage: boolean
) => void = pdForms.addMessage

pdForms.addMessage = addMessage

// Make pdForms accessible from global scope
window.pdForms = pdForms

// Initialize netteForms
Nette.initOnLoad()

// Validate forms on load
document
	.querySelectorAll<HTMLFormElement>('form.pdforms-on-init-validate')
	.forEach((form) => Nette.validateForm(form, true))
