import { ParameterExpand } from '@/js/App/ParameterExpand'
import { Control } from '@peckadesign/pd-naja/dist/types'

class ParameterExpandControl implements Control {
	initialize(context: Element | Document): void {
		const parameters = context.querySelectorAll<HTMLElement>(ParameterExpand.selector)

		parameters.forEach((parameter) => {
			new ParameterExpand(parameter)
		})
	}
}

export default new ParameterExpandControl()
