import React from 'jsx-dom'
import naja from 'naja'
import { InteractionEvent } from 'naja/dist/core/UIHandler'
import { SuccessEvent } from 'naja/dist/Naja'

type SmsCodeButtonText = {
	empty: string
	filled: string
	resend: string
}

declare module 'naja/dist/Naja' {
	interface Options {
		smsCodeRequest?: boolean
	}
}

export class SmsCodeVerification {
	public resendOfferTimeout = 30 * 1000
	public static readonly selector = '.js-sms-code'
	public static readonly className = SmsCodeVerification.selector.substring(1)

	private form: HTMLFormElement | null = null
	private buttonIcon: HTMLElement | null = null

	private resendTimer: ReturnType<typeof setTimeout> | undefined = undefined

	private button: HTMLButtonElement
	private buttonInner: HTMLSpanElement
	private phoneCodeInput: HTMLSelectElement
	private phoneInput: HTMLInputElement

	private verifiedNumber: string | undefined = undefined

	public i18n!: SmsCodeButtonText

	public constructor(form: HTMLFormElement) {
		const button = form.querySelector<HTMLButtonElement>(`${SmsCodeVerification.selector}__button`)
		const buttonInner = button?.querySelector<HTMLSpanElement>('.btn__inner') || null
		const phoneCodeInput = form.querySelector<HTMLSelectElement>(`${SmsCodeVerification.selector}__phone-code`)
		const phoneInput = form.querySelector<HTMLInputElement>(`${SmsCodeVerification.selector}__phone`)

		if (!button || !buttonInner || !phoneCodeInput || !phoneInput) {
			throw new Error('SmsCodeVerification: Missing button or phone (code) input!')
		}

		this.button = button
		this.buttonInner = buttonInner
		this.phoneCodeInput = phoneCodeInput
		this.phoneInput = phoneInput

		this.i18n = {
			empty: this.button.dataset.emptyText || '',
			filled: this.button.dataset.filledText || '',
			resend: this.button.dataset.resendText || ''
		}

		this.verifiedNumber = this.button.dataset.verifiedNumber

		this.phoneCodeInput.addEventListener('change', this.phoneChanged.bind(this))
		this.phoneInput.addEventListener('change', this.phoneChanged.bind(this))

		this.initializeNaja()

		this.phoneChanged()
	}

	public initializeNaja(): void {
		naja.uiHandler.addEventListener('interaction', this.checkExtensionEnabled.bind(this))
		naja.addEventListener('success', this.success.bind(this))
	}

	private checkExtensionEnabled(event: InteractionEvent): void {
		const { element, options } = event.detail

		if (element === this.button) {
			options.smsCodeRequest = true
		}
	}

	private success(event: SuccessEvent): void {
		if (!event.detail.options.smsCodeRequest) {
			return
		}

		clearTimeout(this.resendTimer)

		this.button.disabled = true
		delete this.button.dataset.singleSubmitExtensionDisabled

		this.resendTimer = setTimeout(this.showResendButton.bind(this), this.resendOfferTimeout)
	}

	private showResendButton(): void {
		this.button.disabled = false
		this.buttonInner.innerText = this.i18n.resend
		this.showIcon('retry')
	}

	private showIcon(icon: string): void {
		this.hideIcon()

		this.buttonIcon = (<i class={[`btn__icon icon icon--${icon}`]} aria-hidden="true"></i>) as HTMLElement
		this.button.prepend(this.buttonIcon)
	}

	private hideIcon(): void {
		if (!this.buttonIcon) {
			return
		}

		this.buttonIcon.remove()
		this.buttonIcon = null
	}

	private phoneChanged(): void {
		clearTimeout(this.resendTimer)

		this.hideIcon()

		this.button.disabled = this.getButtonDisabled()
		this.buttonInner.innerText = this.getButtonText()
	}

	private getButtonDisabled(): boolean {
		if (this.phoneCodeInput.value === '' || this.phoneInput.value === '') {
			return true
		}

		return this.verifiedNumber === `${this.phoneCodeInput.value}${this.phoneInput.value}`
	}

	private getButtonText(): string {
		if (
			this.phoneCodeInput.value === '' &&
			this.phoneInput.value === '' &&
			!window.Nette?.validateControl(this.phoneCodeInput, null, true) &&
			!window.Nette?.validateControl(this.phoneInput, null, true)
		) {
			return this.i18n.empty
		}

		let phoneNumber = `${this.phoneCodeInput.value}${this.phoneInput.value}`
		const formatRegexString = this.phoneCodeInput.selectedOptions[0].dataset.formatRegex
		const formatOutput = this.phoneCodeInput.selectedOptions[0].dataset.formatOutput

		if (formatRegexString && formatOutput) {
			phoneNumber = this.formatPhoneNumber(phoneNumber, formatRegexString, formatOutput)
		}

		return this.i18n.filled.replace('%s', phoneNumber)
	}

	private formatPhoneNumber(phoneNumber: string, formatRegexString: string, output: string): string {
		const regex = new RegExp(formatRegexString)

		return phoneNumber.replace(regex, output)
	}
}
