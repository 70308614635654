class Haptic {
	private static instance: Haptic

	public readonly VIBRATE_DEFAULT = 90

	public constructor() {
		if (Haptic.instance) {
			return Haptic.instance
		}

		Haptic.instance = this
	}

	public vibrate(pattern: number | number[]): boolean {
		if (!navigator.vibrate) {
			return false
		}

		return navigator.vibrate(pattern)
	}

	public stopVibrate(): boolean {
		return this.vibrate(0)
	}
}

export default new Haptic()
