import { PdGoogleMapCurrentLocation } from '@/js/App/PdGoogleMap'
import { Extension, Naja, SuccessEvent } from 'naja/dist/Naja'

declare module 'naja/dist/Naja' {
	interface Payload {
		currentLocation?: PdGoogleMapCurrentLocation
		filterStores?: number[]
	}
}

export class PdGoogleMapExtension implements Extension {
	public initialize(naja: Naja): void {
		naja.addEventListener('success', this.setCurrentLocation.bind(this))
	}

	private setCurrentLocation(event: SuccessEvent): void {
		const { payload } = event.detail

		if (payload.filterStores) {
			window.pdGoogleMap.filterMarkers(payload.filterStores)
		}

		if (payload.currentLocation) {
			window.pdGoogleMap.setCurrentLocation(payload.currentLocation)
		}
	}
}
