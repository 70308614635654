import { LinkToTop } from '@/js/App/LinkToTop'
import { Control } from '@peckadesign/pd-naja/dist/types'

class LinkToTopControl implements Control {
	initialize(context: Element | Document): void {
		const element = context.querySelector<HTMLElement>(LinkToTop.selector)

		if (element) {
			new LinkToTop(element)
		}
	}
}

export default new LinkToTopControl()
