import { Slider } from '@/js/App/Slider'
import { Control } from '@peckadesign/pd-naja/dist/types'

class SliderControl implements Control {
	public static readonly selector = '.js-slider:not(.js-daily-feed-ration__slider)'

	initialize(context: Element | Document): void {
		const sliders = context.querySelectorAll<HTMLElement>(SliderControl.selector)

		sliders.forEach((slider: HTMLElement) => new Slider(slider))
	}
}

export default new SliderControl()
