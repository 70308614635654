import { NavBarHighlight } from '@/js/App/NavBarHighlight'
import { Control } from '@peckadesign/pd-naja/dist/types'

class NavBarHighlightControl implements Control {
	public initialize(context: Element | Document): void {
		const element = context.querySelector<HTMLElement>(NavBarHighlight.selector)

		if (element) {
			new NavBarHighlight(element)
		}
	}
}

export default new NavBarHighlightControl()
