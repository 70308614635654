import { Alert } from '@/js/UI/Components/Alert'
import React from 'jsx-dom'

export class ImageUploadPreview {
	public static readonly selector = '.js-image-upload'

	private static className = this.selector.substring(1)

	private static readonly emptySrc =
		'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='

	private element: HTMLElement
	private input: HTMLInputElement
	private preview: HTMLImageElement
	private message: HTMLElement | null

	public constructor(element: HTMLElement) {
		const input = element.querySelector<HTMLInputElement>(`${ImageUploadPreview.selector}__input`)
		const preview = element.querySelector<HTMLImageElement>(`${ImageUploadPreview.selector}__image`)

		if (!input || !preview) {
			throw new Error('ImageUploadPreview: Missing input or image element.')
		}

		this.input = input
		this.preview = preview
		this.message = element.querySelector<HTMLElement>(`${ImageUploadPreview.selector}__message`)
		this.element = element

		this.input.addEventListener('change', this.handleChange.bind(this))
		this.preview.addEventListener('load', this.toggleMinimalDimensionsWarning.bind(this))

		this.element.addEventListener('drop', this.handleDrop.bind(this))
		this.element.ondragover = this.element.ondragenter = (event) => {
			event.preventDefault()
		}
	}

	private handleChange(event: Event): void {
		const files = (event.target as HTMLInputElement).files

		if (!('FileReader' in window) || !files) {
			return
		}

		if (files.length === 0) {
			this.removePreview()
			return
		}

		for (let i = 0; i < files.length; i++) {
			const file = files[i]

			if (!file.type.match('image.*')) {
				continue
			}

			const reader = new FileReader()

			reader.onload = this.changePreview.bind(this)
			reader.readAsDataURL(file)
			return
		}
	}

	private handleDrop(event: DragEvent): void {
		if (!event.dataTransfer) {
			return
		}

		this.input.files = event.dataTransfer.files
		this.input.dispatchEvent(new Event('change', { bubbles: true }))

		event.preventDefault()
	}

	private removePreview(): void {
		this.element.classList.remove(`${ImageUploadPreview.className}--filled-in`)
		this.preview.src = ImageUploadPreview.emptySrc
	}

	private changePreview(event: ProgressEvent<FileReader>): void {
		if (!event.target || !event.target.result) {
			return
		}

		this.element.classList.add(`${ImageUploadPreview.className}--filled-in`)
		this.preview.src = event.target.result as string
	}

	private toggleMinimalDimensionsWarning(): void {
		if (this.preview.src === ImageUploadPreview.emptySrc) {
			this.removeMessage()
			return
		}

		const size = Math.min(this.preview.naturalWidth, this.preview.naturalHeight)

		if ((size >= 110 && this.message) || this.preview.src === ImageUploadPreview.emptySrc) {
			this.removeMessage()
		}

		if (size < 110 && !this.message) {
			this.message = Alert({
				variant: 'warning',
				arrow: 'top',
				centerArrow: true,
				componentClass: 'justify-center',
				textContent: 'Doporučené minimální rozměry fotografie jsou 110×110.'
			})

			this.element.insertAdjacentElement('afterend', this.message)
		}
	}

	private removeMessage(): void {
		if (!this.message) {
			return
		}

		this.message.remove()
		this.message = null
	}
}
