import { waitForElementAnimations } from '@/js/helpers/waitForElementAnimations'
import { Control } from '@peckadesign/pd-naja/dist/types'
import { HTMLCollapsableItem } from 'collapsable.js/dist/CollapsableItem'

class BrandsFilterFocusControl implements Control {
	private productColumnsLayout: HTMLCollapsableItem | null = null
	private brandsFilter: HTMLCollapsableItem | null = null
	private overlay: HTMLElement | null = null

	private mediaQuery = window.matchMedia('(min-width: 992px)')

	private readonly overlayClass = 'overlay-brands-filter'
	private readonly overlayOutClass = 'overlay-brands-filter-fade-out'
	private readonly focusClass = 'param-filter__parameter--focus'

	private focusDuration = 0

	public initialize(context: Element | Document): void {
		const links = context.querySelectorAll('.js-brands-filter-focus')

		if (links.length === 0) {
			return
		}

		this.brandsFilter = document.getElementById('brandsFilter') as HTMLCollapsableItem | null
		this.productColumnsLayout = document.getElementById('productColumnsLayout') as HTMLCollapsableItem
		this.overlay = document.getElementById('overlay')

		links.forEach((link) => link.addEventListener('click', this.focusBrandsFilter.bind(this)))
	}

	private focusBrandsFilter(event: Event): void {
		event.preventDefault()
		event.stopPropagation()

		if (this.mediaQuery.matches) {
			this.desktopFocus()
		} else {
			this.mobileFocus(event)
		}
	}

	private desktopFocus(): void {
		document.documentElement.classList.add(this.overlayClass)

		// Wait for the end of scrolling and any overlay transitions to finish, then start blur timeout
		document.addEventListener(
			'scrollend',
			() => waitForElementAnimations(this.overlay, () => setTimeout(() => this.desktopBlur(), this.focusDuration)),
			{ once: true }
		)

		this.brandsFilter?.scrollIntoView()
		this.brandsFilter?.classList.add(this.focusClass)

		// No support for the scrollend event → immediately trigger the `scrollend`. Looking at you, Safari.
		// https://bugs.webkit.org/show_bug.cgi?id=201556
		if (typeof document.onscrollend === 'undefined') {
			document.dispatchEvent(new Event('scrollend'))
		}
	}

	private desktopBlur(): void {
		document.documentElement.classList.remove(this.overlayClass)
		document.documentElement.classList.add(this.overlayOutClass)

		this.brandsFilter?.classList.remove(this.focusClass)

		waitForElementAnimations(this.brandsFilter, () => document.documentElement.classList.remove(this.overlayOutClass))
	}

	private mobileFocus(event: Event): void {
		this.productColumnsLayout?.collapsableItem.expand(event, null, false)
		this.brandsFilter?.collapsableItem.expand(event, null, false)

		this.brandsFilter?.scrollIntoView({
			behavior: 'instant'
		})
	}
}

export default new BrandsFilterFocusControl()
