import { Instance } from 'tippy.js'

interface HTMLElementWithTooltip extends HTMLElement {
	_tippy: Instance
}

export class HomepageAnimals {
	public static readonly selector = '.js-homepage-animals'
	public tooltipVisilibityDuration = 4000
	public tooltipMinDelay = 2500
	public tooltipMaxDelay = 8000

	private animals: NodeListOf<HTMLElementWithTooltip>
	private tooltipTexts: string[]
	private lastTooltipIndex: number | undefined = undefined

	private isTooltipVisible: boolean = false
	private nextTooltipTimeout: ReturnType<typeof setTimeout> | undefined = undefined

	private isElementIntersecting: boolean = true
	private isDocumentVisible: boolean = true

	public constructor(element: HTMLElement) {
		this.animals = element.querySelectorAll<HTMLElementWithTooltip>(`${HomepageAnimals.selector}__animal`)

		this.tooltipTexts = JSON.parse(element.dataset.tooltipTexts || '[]')

		const observer = new IntersectionObserver(this.handleIntersectionChange.bind(this), {
			threshold: 1
		})

		observer.observe(element)

		document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this))
	}

	private handleIntersectionChange(entries: IntersectionObserverEntry[]): void {
		this.isElementIntersecting = entries[0].isIntersecting
		this.handleChangeState()
	}

	private handleVisibilityChange(): void {
		this.isDocumentVisible = document.visibilityState === 'visible'
		this.handleChangeState()
	}

	private handleChangeState(): void {
		if (this.isDocumentVisible && this.isElementIntersecting) {
			this.planNextTooltip(1000)
		} else {
			clearTimeout(this.nextTooltipTimeout)
		}
	}

	private planNextTooltip(preferenceTimeout?: number): void {
		clearTimeout(this.nextTooltipTimeout)

		if (this.isTooltipVisible || !this.isDocumentVisible || !this.isElementIntersecting) {
			return
		}

		this.nextTooltipTimeout = setTimeout(
			() => {
				this.showRandomTooltip()
			},
			preferenceTimeout || this.getRandomNumber(this.tooltipMinDelay, this.tooltipMaxDelay)
		)
	}

	private showRandomTooltip(): void {
		this.isTooltipVisible = true

		const tooltipText = this.getRandomTooltipText()
		const animal = this.getRandomAnimalElement()

		animal._tippy.setContent(tooltipText)
		animal._tippy.show()

		setTimeout(() => {
			animal._tippy.hide()
			this.isTooltipVisible = false
			this.planNextTooltip()
		}, this.tooltipVisilibityDuration)
	}

	private getRandomTooltipText(): string {
		let index
		do {
			index = this.getRandomIndex(this.tooltipTexts.length)
		} while (index === this.lastTooltipIndex)

		this.lastTooltipIndex = index

		return this.tooltipTexts[index]
	}

	private getRandomAnimalElement(): HTMLElementWithTooltip {
		return this.animals.item(this.getRandomIndex(this.animals.length))
	}

	private getRandomIndex(max: number): number {
		return this.getRandomNumber(0, max)
	}

	private getRandomNumber(min: number, max: number): number {
		return Math.floor(Math.random() * (max - min)) + min
	}
}
