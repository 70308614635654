import { Control } from '@peckadesign/pd-naja/dist/types'
import { getCookie, setCookie } from 'typescript-cookie'

class ChatControl implements Control {
	// The name of the cookie used to track whether the chat has been loaded at any point during the current session.
	// The cookie is valid for the lifetime of the browser tab or window.
	public static readonly COOKIE_NAME = 'szWebChatOpened'

	// Indicates whether the chat has been loaded during the current page load (MPA).
	private isChatLoaded = false
	private observer: MutationObserver | null = null

	private chatElement: HTMLElement | null = null
	private chatButtonElement: HTMLElement | null = null
	private chatBodyElement: HTMLElement | null = null

	private accessToken: string | undefined

	public constructor() {
		this.accessToken = document.body.dataset.chatToken
	}

	public initialize(context: Element | Document): void {
		const buttons = context.querySelectorAll<HTMLElement>('.js-open-chat')

		buttons.forEach((buton) => buton.addEventListener('click', this.handleClick.bind(this)))

		const alreadyOpened = getCookie(ChatControl.COOKIE_NAME)
		if (alreadyOpened) {
			this.loadChat(false)
		}
	}

	private handleClick(event: Event): void {
		event.preventDefault()

		if (this.isChatLoaded) {
			this.openChat()
		} else {
			this.loadChat(true)
		}

		// Trigger click on document so that the main menu is always closed
		document.body.click()
	}

	private openChat(): void {
		if (!this.chatBodyElement || !this.chatButtonElement || this.chatBodyElement.classList.contains('dw-visible')) {
			return
		}

		this.chatButtonElement.click()
	}

	private loadChat(openOnLoad: boolean): void {
		if (!this.accessToken) {
			return
		}

		const daktelaGuiConfig = {
			server: 'https://placekpet.daktela.com/',
			accessToken: this.accessToken
		}

		const daktelaScriptEl = document.createElement('script')
		daktelaScriptEl.src = `${daktelaGuiConfig.server}external/web/web.js`
		daktelaScriptEl.type = 'text/javascript'
		daktelaScriptEl.async = true

		daktelaScriptEl.onload = () => {
			if (!window.daktelaGui) {
				return
			}

			const daktelaWeb = new window.daktelaGui()
			daktelaWeb.init(daktelaGuiConfig)

			this.chatLoadedCallback()
		}

		// There is no API for detecting the ready state of the chat. We create a mutation observer, which detects the
		// creation of chat items and stores them in properties for use in changes. It will also open the chat
		// immediately if requested.
		const observer = new MutationObserver((mutations) => this.handleMutations(mutations, openOnLoad))
		observer.observe(document.body, {
			childList: true,
			subtree: true
		})

		document.getElementsByTagName('head')[0].appendChild(daktelaScriptEl)
	}

	private chatLoadedCallback(): void {
		this.isChatLoaded = true

		setCookie(ChatControl.COOKIE_NAME, true)
	}

	private handleMutations(mutations: MutationRecord[], open: boolean): void {
		for (const mutation of mutations) {
			mutation.addedNodes.forEach((node) => {
				if (node.nodeType === 1 && (node as HTMLElement).id === 'daktela-web') {
					this.chatElement = node as HTMLElement
					this.chatButtonElement =
						this.chatElement.querySelector<HTMLElement>('[data-component-name="mainButton"]') || null
					this.chatBodyElement =
						this.chatElement.querySelector<HTMLElement>('[data-component-name="webContent"]') || null

					if (open) {
						this.openChat()
					}

					this.observer?.disconnect()
					return
				}
			})
		}
	}
}

export default new ChatControl()
