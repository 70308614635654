import { HTMLFormFieldElement, PdFormsAjaxArg, PdFormsExtensionData } from '@/js/UI/naja/PdFormsAjaxValidationExtension'
import { pdForms } from '@/js/UI/validation'

export function PdFormsRules_ajax(
	elem: HTMLFormFieldElement,
	arg: PdFormsAjaxArg,
	val: string,
	value: string,
	callback: any
): boolean {
	if (typeof callback === 'undefined') {
		callback = 'PdFormsRules_ajax'
	}

	const pdFormsExtensionData: PdFormsExtensionData = {
		elem,
		arg,
		callback
	}

	pdForms.naja.makeRequest(
		'GET',
		arg.ajaxUrl,
		{ ...pdForms.getAjaxUrlParameters(elem, arg, val, value, callback) },
		{
			pdFormsValidation: pdFormsExtensionData,
			abort: false,
			history: false,
			unique: false
		}
	)

	return true
}
