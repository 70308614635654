import { BrowserGeolocation } from '@/js/App/BrowserGeolocation'
import { Control } from '@peckadesign/pd-naja/dist/types'

class BrowserGeolocationControl implements Control {
	initialize(context: Element | Document): void {
		const geolocationElements = context.querySelectorAll<HTMLElement>(BrowserGeolocation.selector)

		geolocationElements.forEach((element) => new BrowserGeolocation(element))
	}
}

export default new BrowserGeolocationControl()
