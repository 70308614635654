interface DiscountRange {
	count: number
	discount: number
}

interface DiscountText {
	base: string
	discount: string
}

export class QuantityDiscount {
	private element: HTMLElement
	private input: HTMLInputElement
	private textElement: HTMLElement

	private basePrice: number
	private ranges: DiscountRange[]
	private text: DiscountText

	private formatter: Intl.NumberFormat

	private readonly updateDiscountTextEvents = ['input', 'change'] as const

	public constructor(element: HTMLElement) {
		this.element = element

		const input = element.querySelector<HTMLInputElement>('.js-quantity-discount__input')
		const textElement = element.querySelector<HTMLElement>('.js-quantity-discount__text')

		if (!input || !textElement) {
			throw new Error('QuantityDiscount: Missing input or text element.')
		}

		this.input = input
		this.textElement = textElement

		const basePrice = this.element.dataset.quantityDiscountBasePrice
		const ranges = this.element.dataset.quantityDiscountRanges
		const baseText = this.element.dataset.quantityDiscountBaseText
		const discountText = this.element.dataset.quantityDiscountDiscountText
		const currency = this.element.dataset.quantityDiscountCurrency

		if (!basePrice || !ranges || !baseText || !discountText || !currency) {
			throw new Error('QuantityDiscount: Incomplete dataset.')
		}

		this.formatter = new Intl.NumberFormat(document.documentElement.lang, {
			style: 'currency',
			currency: currency,
			currencyDisplay: 'narrowSymbol',

			// @ts-ignore - this property in unknown to TS
			trailingZeroDisplay: 'stripIfInteger'
		})

		this.basePrice = Number(basePrice)
		this.text = {
			base: baseText,
			discount: discountText
		}

		// Sort ranges descending by `count`
		this.ranges = JSON.parse(ranges)
		this.ranges.sort((a, b) => {
			if (a.count < b.count) {
				return 1
			} else if (a.count > b.count) {
				return -1
			}

			return 0
		})

		// Attach handlers
		this.updateDiscountTextEvents.forEach((evenName) => {
			this.input.addEventListener(evenName, this.updateDiscountText.bind(this))
		})

		this.input.dispatchEvent(new Event('change', { bubbles: true }))
	}

	private updateDiscountText(): void {
		const quantity = parseInt(this.input.value)
		const discount = this.getCurrentDiscountPercentage(quantity)

		if (discount === 0) {
			this.textElement.innerText = this.text.base
		} else {
			const discountValue = this.getCurrentDiscountValue(quantity, discount)
			this.textElement.innerText = this.text.discount.replace('%d', String(this.formatter.format(discountValue)))
		}
	}

	private getCurrentDiscountPercentage(quantity: number): number {
		const currentRange = this.ranges.find((range: DiscountRange) => {
			return quantity >= range.count
		})

		return currentRange?.discount || 0
	}

	private getCurrentDiscountValue(quantity: number, discount: number): number {
		return (this.basePrice / 100) * discount * quantity
	}
}
