import { HomepageAnimals } from '@/js/App/HomepageAnimals'
import { Control } from '@peckadesign/pd-naja/dist/types'

class HomepageAnimalsControl implements Control {
	public initialize(context: Element | Document): void {
		const animalsElements = context.querySelectorAll<HTMLElement>(HomepageAnimals.selector)

		animalsElements.forEach((element) => {
			new HomepageAnimals(element)
		})
	}
}

export default new HomepageAnimalsControl()
