import { Control } from '@peckadesign/pd-naja/dist/types'

class FormEnterControl implements Control {
	public initialize(context: HTMLElement | Document): void {
		const inputs = context.querySelectorAll<HTMLInputElement>('[data-submit-button]')

		inputs.forEach((input) => input.addEventListener('keypress', this.handleKeypress.bind(this)))
	}

	private handleKeypress(event: KeyboardEvent): void {
		const input = event.target as HTMLInputElement
		const form = input.form

		if (event.key !== 'Enter' || !form) {
			return
		}

		const button = form.elements.namedItem(input.dataset.submitButton as string)

		if (!(button instanceof HTMLButtonElement)) {
			return
		}

		event.preventDefault()
		event.stopPropagation()

		button.click()
	}
}

export default new FormEnterControl()
