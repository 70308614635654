import { HTMLFormFieldElement } from '@/js/UI/naja/PdFormsAjaxValidationExtension'

const defaultConditionalContainerStatus = ['STATUS_UNKNOWN_EMAIL']

export function personalDeliveryEmailValidatorCallback(elem: HTMLFormFieldElement, payload: any) {
	if (elem.dataset.pdformsConditionalContainer === undefined) {
		return
	}

	const container = document.getElementById(elem.dataset.pdformsConditionalContainer)

	if (container === null) {
		return
	}

	const dataStatus = elem.dataset.pdformsConditionalContainerStatus
	const statusArray = dataStatus ? (JSON.parse(dataStatus) as string[]) : defaultConditionalContainerStatus
	const isHidden = !statusArray.includes(payload.status)
	const inputs = container.querySelectorAll<HTMLFormFieldElement>('input, select, textarea, button')

	container.hidden = isHidden
	inputs.forEach((input) => {
		input.disabled = isHidden
	})
}
