import { Control } from '@peckadesign/pd-naja/dist/types'

class OnCheckFocusControl implements Control {
	public initialize(context: Element | Document): void {
		const elements = context.querySelectorAll<HTMLInputElement>('[data-on-check-focus]')

		elements.forEach((element) => {
			const focusElement = document.querySelector<HTMLInputElement>(element.dataset.onCheckFocus as string)

			if (!focusElement) {
				return
			}

			element.addEventListener('change', () => {
				if (element.checked) {
					// If the `focusElement` is revealed (e.g. using Nette Toggle or Collapsable.js), we must wait until
					// it is at least partially visible, otherwise the focus will not be moved into it.
					requestAnimationFrame(() => focusElement.focus())
				}
			})
		})
	}
}

export default new OnCheckFocusControl()
