import { SmsCodeVerification } from '@/js/App/SmsCodeVerification'
import { Control } from '@peckadesign/pd-naja/dist/types'

class SmsCodeVerificationControl implements Control {
	initialize(context: Element | Document): void {
		if (context instanceof HTMLFormElement && context.classList.contains(SmsCodeVerification.className)) {
			new SmsCodeVerification(context)
			return
		}

		context
			.querySelectorAll<HTMLFormElement>(SmsCodeVerification.selector)
			.forEach((form) => new SmsCodeVerification(form))
	}
}

export default new SmsCodeVerificationControl()
