import { Control } from '@peckadesign/pd-naja/dist/types'
import { Countdown } from '@/js/App/Countdown'

class CountdownControl implements Control {
	public initialize(context: Element | Document) {
		const countdowns = context.querySelectorAll<HTMLElement>(Countdown.selector)

		countdowns.forEach((countdown) => new Countdown(countdown))
	}
}

export default new CountdownControl()
