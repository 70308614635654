import { MainMenu } from '@/js/App/MainMenu'
import { Control } from '@peckadesign/pd-naja/dist/types'

class MainMenuControl implements Control {
	public initialize(context: Element | Document): void {
		const mainMenu = context.querySelector<HTMLElement>(MainMenu.menuSelector)

		if (mainMenu) {
			new MainMenu(mainMenu)
		}
	}
}

export default new MainMenuControl()
