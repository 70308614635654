import React, { HTMLElementTagNames } from 'jsx-dom'

export type AlertVariant = 'info' | 'warning' | 'error' | 'success'

export type AlertArrow = 'top' | 'bottom' | null

export interface AlertProps {
	tag?: HTMLElementTagNames
	variant?: AlertVariant
	icon?: string | null
	arrow?: AlertArrow
	centerArrow?: boolean
	componentClass?: string | undefined
	textContent: string
}

const variantClass: Record<AlertVariant, string> = {
	info: '[--alert-bg-color:theme(colors.blue.100)] text-blue-500',
	warning: '[--alert-bg-color:theme(colors.yellow.100)] text-primary-500',
	error: '[--alert-bg-color:theme(colors.red.100)] text-red',
	success: '[--alert-bg-color:theme(colors.green.100)] text-green'
}

export const Alert = ({
	tag = 'p',
	variant = 'info',
	icon = variant,
	arrow = null,
	centerArrow = false,
	componentClass = undefined,
	textContent
}: AlertProps): HTMLElement => {
	const Tag = `${tag}` as keyof React.JSX.IntrinsicElements

	return (
		<Tag
			class={[
				'relative flex items-baseline py-1 px-1.5 rounded bg-[--alert-bg-color] text-sm',
				variantClass[variant],
				componentClass
			]}
		>
			{icon !== null ? (
				<i class={[`icon flex-shrink-0 -ml-1 mr-0.5 text-icon icon--${icon}`]} aria-hidden="true"></i>
			) : null}
			<i
				class={[
					'absolute w-0 h-0 border-x-8 border-x-transparent',
					centerArrow ? 'left-1/2 -ml-[8px]' : icon === null ? 'left-2.5' : 'left-4',
					arrow === 'top' ? 'bottom-[calc(100%-1px)] border-b-8 border-b-[--alert-bg-color]' : null,
					arrow === 'bottom' ? 'top-[calc(100%-1px)] border-t-8 border-t-[--alert-bg-color]' : null
				]}
				aria-hidden="true"
			></i>
			<span innerHTML={textContent}></span>
		</Tag>
	) as HTMLElement
}
