import { modal } from '@/js/App/PdModal/PdModal'
import PdModal from '@peckadesign/pd-modal'
import { BeforeCloseEvent } from '@peckadesign/pd-modal/dist/PdModal'
import React from 'jsx-dom'

type PdModalConfirmButton = {
	text?: string
	prependIcon?: string
}

export type PdModalConfirmOptions = {
	title: string
	content: string
	buttons?: {
		accept?: PdModalConfirmButton
		cancel?: PdModalConfirmButton
	}
}

class PdModalConfirm {
	public readonly modal: PdModal
	public cancelButton: HTMLButtonElement | undefined
	public acceptButton: HTMLButtonElement | undefined

	private readonly optionsOpener: HTMLAnchorElement
	private opener: HTMLElement | undefined

	private cancelButtonModalHandler: ((event: CustomEvent) => void) | undefined
	private acceptButtonModalHandler: ((event: CustomEvent) => void) | undefined
	private closeModalHandler: ((event: BeforeCloseEvent) => void) | undefined

	public constructor(modal: PdModal) {
		this.modal = modal

		this.optionsOpener = (
			<a data-modal-hash="true" data-modal-width="610" data-modal-class-name="pd-modal--bg-content"></a>
		) as HTMLAnchorElement
	}

	public confirm(opener: HTMLElement): Promise<boolean> {
		const options = this.getOptions(opener)
		this.opener = opener

		this.cancelButton = (
			<button type="button" class="btn btn--secondary btn--outline">
				{options.buttons?.cancel?.prependIcon ? (
					<i class={`icon icon--${options.buttons.cancel.prependIcon}`} aria-hidden="true"></i>
				) : null}
				<span>{`${options.buttons?.cancel?.text || 'Zrušit'}`}</span>
			</button>
		) as HTMLButtonElement

		this.acceptButton = (
			<button type="button" class="btn" data-naja-spinner="btn">
				{options.buttons?.accept?.prependIcon ? (
					<i class={`icon icon--${options.buttons.accept.prependIcon}`} aria-hidden="true"></i>
				) : null}
				<span>{`${options.buttons?.accept?.text || 'Ok'}`}</span>
			</button>
		) as HTMLButtonElement

		const content = (
			<>
				<div class="mb-contents" innerHTML={options.content}></div>
				<p class="flex items-center justify-between gap-y-1.5 gap-x-2">
					{this.cancelButton}

					{this.acceptButton}
				</p>
			</>
		) as HTMLElement

		this.cancelButton.addEventListener('click', () => this.modal.dispatchEvent(new CustomEvent('cancel')))
		this.acceptButton.addEventListener('click', () => this.modal.dispatchEvent(new CustomEvent('accept')))

		this.optionsOpener.dataset.modalTitle = options.title
		this.modal.open(this.optionsOpener)
		this.modal.content.appendChild(content)

		delete this.optionsOpener.dataset.modalTitle

		return this.waitForUser()
	}

	private getOptions(opener: HTMLElement): PdModalConfirmOptions {
		return {
			title: opener.dataset.confirmHeading as string,
			content: opener.dataset.confirmContent as string,
			buttons: {
				accept: {
					text: opener.dataset.confirmAccept,
					prependIcon: opener.dataset.confirmAcceptPrependIcon
				},
				cancel: {
					text: opener.dataset.confirmCancel,
					prependIcon: opener.dataset.confirmCancelPrependIcon
				}
			}
		}
	}

	private waitForUser(): Promise<boolean> {
		return new Promise((resolveFn) => {
			// Save the handlers so that we can remove them when they are no longer needed.
			this.cancelButtonModalHandler = (event: CustomEvent) => this.resolve(event, resolveFn, false)
			this.acceptButtonModalHandler = (event: CustomEvent) => this.resolve(event, resolveFn, true)
			this.closeModalHandler = (event: BeforeCloseEvent) => this.resolve(event, resolveFn, false)

			this.modal.addEventListener('cancel', this.cancelButtonModalHandler)
			this.modal.addEventListener('accept', this.acceptButtonModalHandler)
			this.modal.addEventListener('beforeClose', this.closeModalHandler, { once: true })
		})
	}

	private resolve(event: CustomEvent, resolveFn: any, resolveAs: boolean): void {
		// When resolving, immediately remove all other resolving handlers.
		this.removeResolveHandlers()

		// Close the modal if the Promise resolves to `false` (canceled). Since we do not close the modal on accept, we
		// expect the original AJAX request to either redraw the modal or use `forceRedirect`. If needed, we may
		// introduce a data attribute, such as `data-confirm-close-on-accept`, to control whether the modal should be
		// closed on accept.
		if (!resolveAs) {
			this.modal.close(event)
		}

		resolveFn(resolveAs)
	}

	private removeResolveHandlers(): void {
		this.modal.removeEventListener('cancel', this.cancelButtonModalHandler ?? (() => {}))
		this.modal.removeEventListener('accept', this.acceptButtonModalHandler ?? (() => {}))
		this.modal.removeEventListener('beforeClose', this.closeModalHandler ?? (() => {}))
	}
}

export default new PdModalConfirm(modal)
