import { Alert } from '@/js/UI/Components/Alert'
import Fuse, { IFuseOptions } from 'fuse.js'

export interface HTMLFulltextElement extends HTMLElement {
	fulltext: Fulltext
}

type FulltextItem = {
	element: HTMLElement
	fulltext: Record<string, string>
}

type FulltextI18n = {
	noResults: string
}

export class Fulltext {
	public static readonly selector = '.js-fulltext'
	public static readonly className = Fulltext.selector.substring(1)
	public static readonly queryThreshold = 1

	private element: Element
	private input: HTMLInputElement
	private clearButton: HTMLButtonElement | null

	private i18n: FulltextI18n

	private fuse: Fuse<FulltextItem>
	private items: FulltextItem[]

	private noResults: HTMLElement

	public fuseOptions: IFuseOptions<FulltextItem> = {
		shouldSort: true,
		threshold: 0.45,
		location: 0,
		distance: 100,
		minMatchCharLength: Fulltext.queryThreshold,
		keys: ['fulltext']
	}

	public constructor(element: HTMLElement) {
		this.element = element

		const input = element.querySelector<HTMLInputElement>(`${Fulltext.selector}__input`)
		const clearButton = element.querySelector<HTMLButtonElement>(`${Fulltext.selector}__clear`)
		const items = element.querySelectorAll<HTMLElement>(`${Fulltext.selector}__item`)

		if (!input || !items) {
			throw new Error('Fulltext: Missing input, list or item elements.')
		}

		this.input = input
		this.clearButton = clearButton
		this.items = this.getItems(items)

		this.i18n = JSON.parse(element.dataset.fulltextI18n || '[]') as FulltextI18n

		this.noResults = Alert({
			arrow: 'top',
			componentClass: '-mt-0.5',
			textContent: this.i18n.noResults
		})

		this.fuse = new Fuse(this.items, this.fuseOptions)
		;(this.element as HTMLFulltextElement).fulltext = this

		this.input.addEventListener('input', this.filter.bind(this))
		this.input.addEventListener('keyup', (event) => {
			if (event.key === 'Escape') {
				this.clearSearch()
			}
		})

		this.clearButton?.addEventListener('click', this.clearSearch.bind(this))
	}

	private filter(): void {
		const query = this.input.value

		if (query.length < Fulltext.queryThreshold) {
			this.resetSearch()
			return
		}

		const results = this.fuse.search(query)

		this.element.classList.toggle(`${Fulltext.className}--no-results`, results.length === 0)

		if (results.length === 0) {
			this.element.insertAdjacentElement('beforeend', this.noResults)
		} else {
			this.noResults.remove()
		}

		this.items.forEach((item) => this.hideItem(item))
		results.forEach((result) => this.showItem(result.item))

		this.element.dispatchEvent(new CustomEvent('fulltextSearch', { bubbles: true, detail: { results } }))
	}

	private resetSearch(): void {
		this.element.classList.remove(`${Fulltext.className}--no-results`)

		this.noResults.remove()
		this.items.forEach((item) => this.showItem(item))

		this.element.dispatchEvent(new CustomEvent('fulltextReset', { bubbles: true }))
	}

	private hideItem(item: FulltextItem): void {
		item.element.hidden = true
	}

	private showItem(item: FulltextItem): void {
		item.element.hidden = false
	}

	private getItems(elements: NodeListOf<HTMLElement>): FulltextItem[] {
		const items: FulltextItem[] = []

		elements.forEach((element) => {
			items.push({
				element,
				fulltext: JSON.parse(element.dataset.fulltext || '') as Record<string, string>
			})
		})

		return items
	}

	public updateItems(): void {
		this.items = this.getItems(this.element.querySelectorAll<HTMLElement>(`${Fulltext.selector}__item`))
		this.fuse.setCollection(this.items)

		this.filter()
	}

	private clearSearch(): void {
		this.input.value = ''
		this.input.dispatchEvent(new Event('input', { bubbles: true }))
	}
}
