// Declare currently unexported types of noUiSlider. Note that this may change in future releases!
import noUiSlider, { API, PartialFormatter, PipsMode } from 'nouislider'

export type SliderValue = string
export type SliderValueId = string | number

export type SliderStep = {
	id: SliderValueId
	value: SliderValue
}
export type SliderData = SliderStep[]

export interface HTMLSliderElement extends HTMLElement {
	slider: Slider
}

export type RangeSliderValue = [SliderValue, SliderValue]
export type RangeSliderValueId = [SliderValueId, SliderValueId]

type OptionTooltip = boolean | PartialFormatter | (boolean | PartialFormatter)[] | undefined

export class Slider {
	public readonly api: API
	public static readonly selector = '.js-slider'

	private sliderData: SliderData

	private element: HTMLElement
	private handleValuesElements: HTMLElement[] = []
	private handleValuesInputElements: HTMLInputElement[] = []
	private formSubmit: HTMLButtonElement | undefined | null = null

	constructor(element: HTMLElement) {
		this.element = element
		this.sliderData = JSON.parse(element.dataset.sliderData as string) as SliderData

		const start = this.getOptionStart()

		start.forEach((value, index) => {
			const handleValueElement = this.element.querySelector<HTMLElement>(`${Slider.selector}__handle-value-${index}`)
			const handleValueInputElement = this.element.querySelector<HTMLInputElement>(
				`${Slider.selector}__handle-input-${index}`
			)

			if (handleValueElement) {
				this.handleValuesElements.push(handleValueElement)
			}

			if (handleValueInputElement) {
				this.handleValuesInputElements.push(handleValueInputElement)
			}
		})

		if (this.handleValuesInputElements.length) {
			this.formSubmit = this.handleValuesInputElements[0].form?.querySelector<HTMLButtonElement>('[type=submit]')
		}

		this.api = noUiSlider.create(element, {
			start: start,
			step: 1,
			connect: true,
			behaviour: 'smooth-steps-tap',
			range: {
				min: 0,
				max: this.sliderData.length - 1
			},
			tooltips: this.getOptionTooltips(),
			pips: this.getOptionPips()
		})

		this.api.on('slide', this.handleUpdate.bind(this))
		this.api.on('set', this.handleSet.bind(this))
		;(element as HTMLSliderElement).slider = this
	}

	private getOptionStart(): number[] {
		const dataStart = this.element.dataset.sliderStart
			? (JSON.parse(this.element.dataset.sliderStart) as number[])
			: undefined
		const isRange = this.element.dataset.sliderRange !== undefined

		const start = isRange ? [0, this.sliderData.length - 1] : [0]

		if (!dataStart) {
			return start
		}

		return start.map((value, index) => this.findClosestIndex(dataStart[index]))
	}

	// Returns any, because type `Pips` is not exported https://github.com/leongersen/noUiSlider/issues/1143
	private getOptionPips(): any {
		const pips = this.element.dataset.sliderPips

		if (pips === undefined) {
			return undefined
		}

		return {
			mode: PipsMode.Steps,
			density: 100
		}
	}

	private getOptionTooltips(): OptionTooltip {
		const tooltips = this.element.dataset.sliderTooltips

		if (tooltips) {
			return (JSON.parse(tooltips) as string[]).map((tooltip: string) => {
				return {
					to: () => tooltip
				}
			})
		}

		return {
			to: (value) => this.sliderData[Math.round(value)].value
		}
	}

	private handleUpdate(sliderValues: (string | number)[], handle: number): void {
		if (this.handleValuesElements[handle]) {
			const values = this.getValue()
			this.handleValuesElements[handle].innerHTML = Array.isArray(values) ? values[handle] : values
		}

		if (this.handleValuesInputElements[handle]) {
			const valuesId = this.getId()
			this.handleValuesInputElements[handle].value = String(Array.isArray(valuesId) ? valuesId[handle] : valuesId)
		}
	}

	private handleSet(): void {
		this.formSubmit?.click()
	}

	private findClosestIndex(value: number) {
		const closestStep = this.sliderData.reduce((prev, current) => {
			return Math.abs((current.id as number) - value) < Math.abs((prev.id as number) - value) ? current : prev
		})

		return this.sliderData.indexOf(closestStep)
	}

	public getValue(): SliderValue | RangeSliderValue {
		return this.getValueOrId('value') as SliderValue | RangeSliderValue
	}

	public getId(): SliderValueId | RangeSliderValueId {
		return this.getValueOrId('id') as SliderValueId | RangeSliderValueId
	}

	private getValueOrId(key: 'id' | 'value'): SliderValue | SliderValueId | RangeSliderValue | RangeSliderValueId {
		const values = this.api.get(true) as number | [number, number]

		if (Array.isArray(values)) {
			return values.map((value) => this.sliderData[Math.round(value)][key]) as number | [number, number]
		}

		return this.sliderData[Math.round(values)][key]
	}
}
