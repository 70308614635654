import { getNonStickySpinnerProps, getSpinnerProps, Spinner } from '@/js/UI/Components/Spinner'
import { CookieConsentExtension } from '@/js/UI/naja/CookieConsentExtension'
import { PdGoogleMapExtension } from '@/js/UI/naja/PdGoogleMapExtension'
import { PdModalConfirmExtension } from '@/js/UI/naja/PdModalConfirmExtension'
import { PdFormsAjaxValidationExtension } from '@/js/UI/naja/PdFormsAjaxValidationExtension'
import naja from 'naja'
import {
	AjaxModalExtension,
	AjaxModalPreventRedrawExtension,
	AjaxOnceExtension,
	BtnSpinnerExtension,
	ForceRedirectExtension,
	ForceReplaceExtension,
	ScrollToExtension,
	SingleSubmitExtension,
	SnippetFormPartExtension,
	SpinnerExtension,
	SuggestExtension,
	ToggleClassExtension
} from '@peckadesign/pd-naja'
import { PdModalNajaAdapter } from '@peckadesign/pd-modal'
import { modal } from '@/js/App/PdModal/PdModal'

const spinnerExtension = new SpinnerExtension(Spinner, getSpinnerProps)

naja.registerExtension(
	new AjaxModalExtension(
		new PdModalNajaAdapter(modal, ['snippet--pdModalTitle', 'snippet--pdModalPerex', 'snippet--pdModal'])
	)
)
naja.registerExtension(new AjaxModalPreventRedrawExtension())
naja.registerExtension(new AjaxOnceExtension())
naja.registerExtension(new BtnSpinnerExtension(Spinner, getNonStickySpinnerProps))
naja.registerExtension(new CookieConsentExtension())
naja.registerExtension(new PdModalConfirmExtension(modal))
naja.registerExtension(new ForceReplaceExtension())
naja.registerExtension(new ScrollToExtension())
naja.registerExtension(new SingleSubmitExtension())
naja.registerExtension(new SnippetFormPartExtension())
naja.registerExtension(new SuggestExtension(spinnerExtension, undefined, getNonStickySpinnerProps))
naja.registerExtension(new ToggleClassExtension())
naja.registerExtension(spinnerExtension)
naja.registerExtension(new ForceRedirectExtension())

naja.registerExtension(new PdFormsAjaxValidationExtension(Spinner, getNonStickySpinnerProps))

naja.registerExtension(new PdGoogleMapExtension())

// We must attach Naja to window load event.
if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja))
} else {
	naja.initialize()
}
