import mediaGalleryContentLoaderOptions from '@/js/App/PdModal/mediaGalleryContentLoaderOptions'
import { Spinner } from '@/js/UI/Components/Spinner'
import PdModal, { HTMLContentLoader, MediaGalleryContentLoader, NajaContentLoader } from '@peckadesign/pd-modal'
import { BeforeOpenEvent } from '@peckadesign/pd-modal/dist/PdModal'
import React from 'jsx-dom'

export const modal = new PdModal({
	className: 'prose',
	spinner: Spinner({}),
	i18n: {
		lv: {
			close: 'Aizvērt dialoglodziņu',
			defaultTitle: 'Dialoglodziņš',
			loading: 'Satura ielādēšana'
		},
		ru: {
			close: 'Закрыть диалоговое окно',
			defaultTitle: 'Диалоговое окно',
			loading: 'Загрузка содержимого'
		}
	},
	template: function (this: PdModal): HTMLElement {
		const i18n = this.i18n[this.options.language]

		return (
			<div
				class="pd-modal"
				id="pdModal"
				aria-hidden="true"
				aria-labelledby="snippet--pdModalTitle"
				aria-describedby="snippet--pdModalPerex"
			>
				<div class="pd-modal__overlay" data-a11y-dialog-hide={true}></div>
				<div class="pd-modal__window" role="document">
					<div class="pd-modal__dialog ajax-wrap">
						<header class="pd-modal__header container">
							<button type="button" data-a11y-dialog-hide={true} class="pd-modal__close" aria-label={i18n.close}>
								{i18n.close}
							</button>

							<h1 class="pd-modal__title" id="snippet--pdModalTitle">
								{i18n.defaultTitle}
							</h1>

							<p id="snippet--pdModalPerex" class="pd-modal__lead lead mb-1.5 empty:hidden"></p>
						</header>

						<div class="pd-modal__content container" id="snippet--pdModal"></div>
					</div>
				</div>
			</div>
		) as HTMLElement
	}
})

// Setup content loaders
modal.registerContentLoader(new HTMLContentLoader(modal))
modal.registerContentLoader(new NajaContentLoader(modal))

const mediaGalleryContentLoader = new MediaGalleryContentLoader(modal, mediaGalleryContentLoaderOptions)
mediaGalleryContentLoader.classList.push('pd-modal--bg-content')
modal.registerContentLoader(mediaGalleryContentLoader)

// Automatically get perex from opener data attribute
modal.addEventListener('beforeOpen', (event: BeforeOpenEvent) => {
	const perexElement = modal.window.querySelector('#snippet--pdModalPerex')

	if (!perexElement) {
		return
	}

	const perex = (event.detail.opener as HTMLElement | SVGElement | null)?.dataset.modalPerex

	perexElement.innerHTML = perex ? perex : ''
})

// Automatically scroll to the top of the modal, when content is loaded
modal.addEventListener('load', () => {
	modal.window.scrollTop = 0
})
