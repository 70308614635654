import { Control } from '@peckadesign/pd-naja/dist/types'

// Firefox currently allows non-numeric input within `input type="number"`. This is a generalised solution, where if the
// input has a `data-allowed-characters` attribute, we check if the character matches the regex in the attribute. If
// not, the input of the character is prevented.
// See: https://bugzilla.mozilla.org/show_bug.cgi?id=1466793
class InputAllowedCharactersControl implements Control {
	initialize(context: Element | Document): void {
		const inputs = context.querySelectorAll<HTMLInputElement>('[data-allowed-characters]')

		inputs.forEach((input) => {
			const allowed = input.dataset.allowedCharacters as string

			input.addEventListener('keypress', (event: KeyboardEvent) => {
				if (!event.key.match(allowed)) {
					event.preventDefault()
				}
			})
		})
	}
}

export default new InputAllowedCharactersControl()
