import { ImageUploadPreview } from '@/js/App/ImageUploadPreview'
import { Control } from '@peckadesign/pd-naja/dist/types'

class ImageUploadPreviewControl implements Control {
	initialize(context: Element | Document) {
		const imageUploadElements = context.querySelectorAll<HTMLElement>(ImageUploadPreview.selector)

		imageUploadElements.forEach((element) => {
			new ImageUploadPreview(element)
		})
	}
}

export default new ImageUploadPreviewControl()
