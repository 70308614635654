import { Control } from '@peckadesign/pd-naja/dist/types'
import naja from 'naja'

class EventCallbackControl implements Control {
	private findElements(context: Element | Document): HTMLElement[]
	{
		let elements = Array.from(context.querySelectorAll<HTMLElement>('.js-event-callback'))

		if (elements.length === 0 && context instanceof HTMLElement && context.matches('.js-event-callback')) {
			elements = [context]
		}

		return elements
	}

	public initialize(context: Element | Document): void {
		this.findElements(context).forEach(element => this.initializeElement(element))
	}

	private initializeElement(element: HTMLElement): void
	{
		let callbackUrl = element.dataset.callbackEventUrl
		let eventName = element.dataset.callbackEvent
		if (callbackUrl === undefined || eventName === undefined) {
			return
		}

		element.addEventListener(
			eventName,
			() => naja.makeRequest('get', callbackUrl, null, {
				history: false,
				spinnerInitiator: element,
			})
		)
	}
}

export default new EventCallbackControl()
