export class LinkToTop {
	public static selector: string = '.js-link-to-top'

	private element: HTMLElement
	private link: HTMLAnchorElement

	public constructor(element: HTMLElement) {
		const link = element.querySelector<HTMLAnchorElement>(`${LinkToTop.selector}__link`)

		if (!link) {
			throw new Error('Missing link element in LinkToTop component.')
		}

		this.element = element
		this.link = link

		const targetElement = this.getTargetElement()

		if (!targetElement) {
			return
		}

		const observer = new IntersectionObserver(this.handleIntersection.bind(this), {
			threshold: 0,
			rootMargin: '50% 0px 0px 0px'
		})

		observer.observe(targetElement)
	}

	private getTargetElement(): HTMLElement | null {
		const targetId = this.link.getAttribute('href')?.substring(1) || ''
		return document.getElementById(targetId)
	}

	private handleIntersection(entries: IntersectionObserverEntry[]): void {
		const entry = entries[0]

		this.element.classList.toggle('active', !entry.isIntersecting && entry.boundingClientRect.top < 0)
	}
}
