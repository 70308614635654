export class NavBarHighlight {
	public static selector = '.js-nav-bar-highlight'
	private observer: IntersectionObserver

	private navBar: HTMLElement
	private navBarLinks: HTMLAnchorElement[]

	private allEntries: Map<string, IntersectionObserverEntry> = new Map()

	private interactionInvokedScroll: boolean = false

	public constructor(navBar: HTMLElement) {
		this.navBar = navBar

		this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
			threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
		})

		this.navBarLinks = Array.from(this.navBar.querySelectorAll<HTMLAnchorElement>(`${NavBarHighlight.selector}__link`))
		this.navBarLinks.forEach((link) => this.initializeNavBarLink(link))

		this.navBar
			.querySelector(`${NavBarHighlight.selector}__top`)
			?.addEventListener('click', this.handleNavBarLinkClick.bind(this))
	}

	private initializeNavBarLink(link: HTMLAnchorElement): void {
		const id = link.getAttribute('href')?.substring(1) || ''
		const navBarAreaHeadings = document.getElementById(id)

		if (!navBarAreaHeadings) {
			throw new Error(`NavBarHighlight: Missing target element for NavBar link! Unable to find element '#${id}'.`)
		}

		link.addEventListener('click', this.handleNavBarLinkClick.bind(this))

		this.observer.observe(navBarAreaHeadings)
	}

	private handleIntersection(entries: IntersectionObserverEntry[]): void {
		this.updateAllEntries(entries)

		const highlightArea = this.getAreaToHighlight()
		let highlightLink = undefined

		if (highlightArea) {
			highlightLink = this.getNavBarLinkById(highlightArea.target.id)
		}

		if (!this.interactionInvokedScroll) {
			this.highlight(highlightLink)
		}
	}

	private handleNavBarLinkClick(event: Event): void {
		this.interactionInvokedScroll = true

		this.highlight(event.target as HTMLAnchorElement)

		document.addEventListener(
			'scrollend',
			() => {
				this.interactionInvokedScroll = false
			},
			{ once: true }
		)
	}

	private getNavBarLinkById(id: string): HTMLAnchorElement | undefined {
		return this.navBarLinks.find((link) => link.getAttribute('href') === `#${id}`)
	}

	private updateAllEntries(updatedEntries: IntersectionObserverEntry[]): void {
		updatedEntries.forEach((entry) => {
			this.allEntries.set(entry.target.id, entry)
		})
	}

	private getAreaToHighlight(): IntersectionObserverEntry | null {
		let dominantEntry: IntersectionObserverEntry | null = null
		let dominantEntryViewportRatio: number = 0

		// Approximate viewport ratio of the item when its link is highlighted. If the item occupies more than
		// `ratioThreshold` of the viewport (or `ratioThreshold` of the sum of currently highlighted `entry` height and
		// its height), its link is highlighted.
		const ratioThreshold = 0.6

		this.allEntries.forEach((entry) => {
			const viewportRatio = entry.intersectionRect.height / entry.rootBounds!.height

			if (dominantEntry === null && entry.isIntersecting) {
				dominantEntry = entry
				dominantEntryViewportRatio = viewportRatio

				return
			}

			if (dominantEntry === null) {
				return
			}

			// When both items are fully visible, highlight the link of the first one
			if (dominantEntry.intersectionRatio === 1 && entry.intersectionRatio === 1) {
				return
			}

			if (dominantEntry.intersectionRatio === 1 || entry.intersectionRatio === 1) {
				if (
					(dominantEntry.intersectionRatio === 1 && viewportRatio > ratioThreshold) ||
					(entry.intersectionRatio === 1 && dominantEntryViewportRatio < 1 - ratioThreshold)
				) {
					dominantEntry = entry
					dominantEntryViewportRatio = viewportRatio
				}

				return
			}

			// `viewportRatio + dominantViewportRatio` can be less than 1, so we can't simply check
			// `viewportRatio > ratioThreshold && dominantEntryViewportRatio < 1 - ratioThreshold`, but instead we check
			// if their heights are in the desired ratio (defined by `ratioThreshold`).
			if (
				entry.intersectionRect.height >
				dominantEntry.intersectionRect.height / (1 - ratioThreshold) - dominantEntry.intersectionRect.height
			) {
				dominantEntry = entry
				dominantEntryViewportRatio = viewportRatio
			}
		})

		return dominantEntry
	}

	private highlight(navBarLink: HTMLAnchorElement | undefined): void {
		this.navBarLinks.forEach((link) => link.classList.toggle('active', link === navBarLink))
	}
}
