import { modal } from '@/js/App/PdModal/PdModal'
import { Control } from '@peckadesign/pd-naja/dist/types'
import tippy, { Instance, Props, sticky } from 'tippy.js'

declare global {
	interface Window {
		pdTooltipControl: TooltipControl
	}
}

export interface HTMLTooltipButton extends HTMLButtonElement {
	_tippy: Instance
}

export const tooltipOptions: Partial<Props> = {
	maxWidth: 250,
	animation: 'shift-away',
	allowHTML: true,
	offset: [0, 2],
	plugins: [sticky],
	appendTo: () => {
		if (modal.isOpen) {
			return modal.window
		} else {
			return document.body
		}
	},
	popperOptions: {
		modifiers: [
			{
				name: 'arrow',
				options: {
					padding: 20
				}
			},
			{
				name: 'preventOverflow',
				options: {
					tetherOffset: 28
				}
			}
		]
	}
}

class TooltipControl implements Control {
	public initialize(context: Element | Document): void {
		const tippyElements = this.getTippyElements(context)

		tippy(tippyElements, tooltipOptions)
	}

	public destroy(context: Element): void {
		const tippyElements = this.getTippyElements(context)

		tippyElements.forEach((element) => {
			;(element as HTMLTooltipButton)._tippy?.destroy()
		})
	}

	private getTippyElements(context: Element | Document): HTMLButtonElement[] {
		let tippyElements = Array.from(context.querySelectorAll<HTMLButtonElement>('.js-tooltip'))

		if (tippyElements.length === 0 && context instanceof HTMLButtonElement && context.matches('.js-tooltip')) {
			tippyElements = [context]
		}

		return tippyElements
	}
}

window.pdTooltipControl = new TooltipControl()
export default window.pdTooltipControl
