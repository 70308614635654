import { isDatasetTruthy } from '@peckadesign/pd-naja'
import { Control } from '@peckadesign/pd-naja/dist/types'
import naja from 'naja'

class AutoSubmitControl implements Control {
	public static readonly selector = '.js-auto-submit'

	public initialize(context: Element | Document): void {
		const forms = context.querySelectorAll<HTMLFormElement>(AutoSubmitControl.selector)

		forms.forEach((form) => this.initializeForm(form))
	}

	private initializeForm(form: HTMLFormElement): void {
		const submit = form.querySelector<HTMLElement>(`${AutoSubmitControl.selector}__submit`)
		const timeout = form.dataset.autosubmitTimeout ? parseInt(form.dataset.autosubmitTimeout) : 200
		let timer: ReturnType<typeof setTimeout> | undefined = undefined

		form.addEventListener('change', (event: Event | CustomEvent) => {
			clearTimeout(timer)

			if ((event as CustomEvent).detail?.isTrigger) {
				timer = setTimeout(() => this.handleChange(event.target as HTMLElement, submit, form), timeout)
			} else {
				this.handleChange(event.target as HTMLElement, submit, form)
			}
		})
	}

	private handleChange(target: HTMLElement, submit: HTMLElement | null, form: HTMLFormElement): void {
		if (isDatasetTruthy(target, 'autoSubmitDisabled')) {
			return
		}

		if (submit) {
			submit.click()
		} else {
			naja.uiHandler.submitForm(form)
		}
	}
}

export default new AutoSubmitControl()
