import { Fulltext, HTMLFulltextElement } from '@/js/App/Fulltext'
import { Control } from '@peckadesign/pd-naja/dist/types'

class FulltextControl implements Control {
	public initialize(context: Element | Document): void {
		// Initialize fulltext inside snippets (or document)
		context.querySelectorAll<HTMLElement>(Fulltext.selector).forEach((fulltext) => {
			new Fulltext(fulltext)
		})

		// Initialize snippets within fulltext (fulltext list may be redrawn snippet itself)
		const fulltextList = context as HTMLElement
		if (context instanceof Element && fulltextList.classList.contains(`${Fulltext.className}__list`)) {
			const fulltextElement = fulltextList.closest(Fulltext.selector) as HTMLFulltextElement

			fulltextElement.fulltext.updateItems()
		}
	}
}

export default new FulltextControl()
